.error-div__container {
    width: 100%;
    margin-top: -8px;
    margin-bottom: 10px;
    display: none;
}

.error-div__container.show {
    display: block;
}

.error-div__icon, .error-div__text {
    color: #d93025;
}

.error-div__icon {
    margin-right: 5px;
    font-size: 15px;
}

.error-div__text {
    font-size: 15px;
    font-weight: 600;
    font-family: 'Montserrat', 'sans-serif';
}

@media (max-width: 1170px) {
    .error-div__icon {
        font-size: 13px;
    }
    
    .error-div__text {
        font-size: 14px;
    }
}
.search__suggestions-popup {
    width: 100%;
    position: absolute;
    top: 114%;
    border: 1px solid rgb(21, 27, 34);
    background-color: rgb(48, 51, 57);
    font-family: 'Montserrat', sans-serif;
    color: white;
    font-weight: 500;
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #eaebf3 #353535;
}

//WEBKIT SCROLLBAR STYLES
.search__suggestions-popup::-webkit-scrollbar {
    width: 0.5em;
}

.search__suggestions-popup::-webkit-scrollbar-track {
    background: #353535;
}

.search__suggestions-popup::-webkit-scrollbar-thumb {
    background: #eaebf3;
    border-radius: 20px;
}

.search__suggestions-item {
    padding: 14px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    margin: 0;
    transition: background 200ms;
}

.search__suggestions-item-details {
    width: 70%;
}

.search__suggestions-item-details p {
    margin-bottom: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.search__suggestions-item.artwork {
    display: flex;
    align-items: center;
}

.search__suggestions-item-image {
    width: 30%;
    max-width: 100px;
    aspect-ratio: 1/1;
    margin-right: 15px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.strong {
    font-weight: bold;
    color: grey;
}

.search__suggestions-item.header {
    border-bottom: 1px solid grey;
}

.search__suggestions-item:hover {
    background-color: rgb(39 41 46);
}

.search__suggestions-item-details-artist {
    margin-top: 2px;
    color: #8f8f8f;
}


.search__pulseLoader {
    width: 2em;
    height: 2em;
    border-radius: 2em;
    background-color: white;
    outline: 1px solid transparent;
    animation: search-pulseanim 0.8s ease-in-out infinite;
}

.search__suggestions-item.artwork.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
}

.search__suggestions-item.artwork.loading:hover {
    background-color: transparent;
}
  
@keyframes search-pulseanim {
    0% { transform: scale(0); opacity: 0.8;}
    100% { transform: scale(1); opacity: 0;}
}


@media (max-width: 916px) {
    .search__suggestions-popup {
        font-size: 15px;
    }  
}

@media (max-width: 712px) {
    .search__suggestions-item{
        font-size: 14px;
    }  
}

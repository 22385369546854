* {
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    scrollbar-width: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
    display: none;
}

a {
    text-decoration: none;
}

html {
    scroll-behavior: auto !important;
}

:root {
    --gallery-view-navbar-text: #FFFFFF;
    --background: #242424;
    --neumorphism-gallery-view: 5px 5px 5px black;
    --gallery-view-printing-name: #FFFFFF;
    --gallery-view-printing-details: #838383;
    --gallery-view-icon-color: invert(100%) sepia(100%) saturate(0%) hue-rotate(122deg) brightness(103%) contrast(103%);
    --gallery-view-gradient: linear-gradient(0deg, rgba(36,36,36,0) 0%, rgba(36,36,36,0.3) 15%, rgba(36,36,36,0.6) 31%, rgba(36,36,36,0.8) 49%, rgba(36,36,36,0.9) 67%, rgba(36,36,36,1) 87%);
    --box-shadow-navbar: 1px 5px 10px black;
    --painting-view-artist: #BEBBBB;
    --painting-view-neumorphism: inset 5px 5px 10px #111111, inset -5px -5px 10px #373737; 
    --painting-view-inset: inset 7px 7px 8px #0e0e0e,
    inset -7px -7px 8px #3a3a3a;
    // --change-mode-transition: all 600ms ease;
    --background-login: #242424;
    --audio-neumorphism: 2px 2px 1px #141414, -1px -1px 3px #343434;
    --border-wishlist: 3px solid white;
    --rope-color: #eaebf3;
    --rope-fill: #242424;
    --timer-color: #BEBBBB;
    --cart-product-inset: inset 3px 3px 1px #171717, inset -3px -3px 2px #313131;
    --cart-product-details-color: #838383;
    --gray-dark-mode: #afa7a7;
    // --gradient-login: linear-gradient(90deg, rgba(255,190,46,1) 0%, rgba(190,0,191,1) 100%);
   // --gradient-login: linear-gradient(90deg, rgba(255,190,46,1) 0%, rgba(224,101,114,1) 42%, rgba(190,0,191,1) 100%);
//    --gradient-login: linear-gradient(90deg, rgba(255,190,46,1) 0%, rgba(224,101,114,1) 38%, rgba(205,45,156,1) 52%, rgba(190,0,191,1) 100%);
   --gradient-login: linear-gradient(90deg, rgba(255,183,0,1) 0%, rgba(205,0,193,1) 100%);
}
.showroom__masonry {
    width: 100%;
}

.showroom__artwork-card-container {
    position: relative;
}

.showroom__artwork-card-button{
    position: absolute;
    width: 25px;
    border-radius: 5px;
    aspect-ratio: 1/1;
    background: #242424;
    top: 6px;
    right: 10px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;  
    font-size: 22px;  
}

.showroom__artwork-card-button.edit{
    border: 2px solid rgb(255,133,28);
    font-size: 22px;
}

.showroom__artwork-card-button.edit i {
    display: none;
}

.showroom__artwork-card-button.edit i.selected {
    display: block;
}

.showroom__artwork-card-container.edit {
    cursor: pointer;
}

.showroom__artwork-card-container.edit.true {
    cursor: pointer;
}

.showroom__artwork-card-container.edit.true.atrue {
    cursor: pointer;
}

.selected-artwork__backdrop {
    position: absolute;
    width: 100%;
    background: rgba(255,133,28,0.3);
    height: 100%;
    z-index: 2;
    top: 0;
    border: 2px solid rgb(255 133 28);
}

@media (max-width: 1355px) {
    .showroom__masonry .artwork__name {
        font-size: 1.2rem;
    }
}

@media (max-width: 1355px) {
    .showroom__masonry .my-masonry-grid_column:nth-child(1) {
        padding-left: 30px;
    }

    .showroom__masonry .my-masonry-grid_column {
        padding-left: 17px;
    }

    .showroom__masonry .artwork__name {
        font-size: 1rem;
    }

    .showroom__masonry .artwork-details {
        font-size: 15px;
    }
}
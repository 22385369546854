.admin-header-accordeon {
    width: 100%;
    position: relative;
    border-radius: 20px;
    cursor: pointer;
}

.admin-accordeon__selected-option {
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    font-size: 19px;
    font-weight: 500;
    color: rgb(255,133,28);
    border: 3px solid rgb(255,133,28);
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: white;
    padding: 5px;
    z-index: 3;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
}

.admin-accordeon__selected-option:hover {
    background: rgb(255,133,28);
    color: white;
}

.admin-accordeon__selected-option.selected {
    background: rgb(255,133,28);
    color: white;
}

.admin-accordeon-selected-option__option {
    margin-right: 5px;
}

.admin-accordeon-selected-option__icon {
    transition: transform 0.3s ease-in-out;
}

.admin-accordeon__options {
    width: 100%;
    max-height: 0;
    background: #242424;
    transition: max-height 0.2s ease-in-out, padding 0.2s ease-in-out;
    border-radius: 20px;
    position: absolute;
    top: 0;
    z-index: 1;
    border: 3px solid rgb(255,133,28);
    overflow: hidden;
}

.control-overflow {
    width: 100%;
    height: 20px;
    position: absolute;
    top: 0;
    background: white;
    z-index: 2;
}

.admin-header-accordeon.active .admin-accordeon__options {
    max-height: 300px;
    padding-top: 55px;
    padding-bottom: 10px;
}

.admin-header-accordeon.active .admin-accordeon-selected-option__icon{
    transform: rotate(180deg);
}

.admin-accordeon-options__option {
    margin: 0px;
    margin-bottom: 8px;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: white;
    cursor: pointer;
    padding-left: 15px;
    padding-right: 15px;
}


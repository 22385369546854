.tags-container {
    width: 100%;
}

.tag-section__heading {
    font-family: 'Montserrat', sans-serif;
    color: var(--gallery-view-navbar-text);
    transition: var(--change-mode-transition);
    font-weight: 500;
    font-size: 30px;
    margin-left: 20px;
    margin-bottom: 0px;
}

.margin-tag {
    margin-bottom: 20px;
}

.tags-limited-container {
    display: flex;
    align-items: center;
}

.tags-limited-container i {
    margin-left: 7px;
    font-size: 20px;
    color: var(--gallery-view-printing-name);
    transition: var(--change-mode-transition);
    transition: opacity 200ms;
    cursor: pointer;
    opacity: 0.6;
}

.tags-limited-container i:hover {
    opacity: 1;
}


@media (max-width: 1412px) {
    .tag-section__heading {
        font-size: 26px;
    }

    .tags-limited-container {
        margin-top: 40px;
    }
}


@media (max-width: 1000px) {
    .tag-section__heading {
        font-size: 22px;
    }

    .tags-limited-container {
        margin-top: 35px;
    }
}


@media (max-width: 868px) {
    .tags-container {
        margin-top: 39px;
    }
}

.showroom__container {
    width: 100%;
    min-height: 100vh;
    background-color: #242424;
}

.showroom__wrap {
    width: 94%;
    margin: 0 auto;
}

.showroom-header__container {
    width: 100%;
    background: #242424;
    position: sticky;
    top: 0;
    padding-bottom: 20px;
    box-shadow: 1px 5px 10px black;
    z-index: 3;
}

.showroom__header {
    width: 94%;
    margin: 0 auto;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #242424;
    position:relative;
}

.showroom-header__accordeon {
    width: 28%;
}

.showroom-header__add {
    width: 25%;
}

.showroom-header__gallery-name {
    width: 100%;
    position:absolute;
}

.showroom-header__gallery-name h1 {
    width: 100%;
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-size: 38px;
    text-align: center;
    margin-bottom: 0px;
}

.showroom-header__accordeon .admin-accordeon__selected-option {
    background: #242424;
    color: white;
}

.showroom-header__accordeon .admin-header-accordeon.active .admin-accordeon__options {
    max-height: 300px;
}
.showroom-header__accordeon .control-overflow {
    background: #242424;
}

.showroom-header__accordeon .admin-header-accordeon {
    width: 50%;
    margin-left: auto;
}

.showroom-masonry__container {
    width: 100%;
    padding-top: 20px;
}

.showroom-edit-button {
    width: fit-content;
    border-radius: 20px;
    border: 2px solid rgb(255 133 28);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 20px;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    cursor: pointer;
    transition: background-color 100ms ease-in-out;
    z-index: 2;
    background: #242424;
}

.showroom-edit-button span {
    color: white;
    font-family: 'Montserrat';
    font-size: 20px;
    transition: color 100ms ease-in-out;
}

.showroom-edit-button svg {
    width: 15px;
    fill: white;
    margin-right: 5px;
    transition: fill 100ms ease-in-out;
}

.showroom-edit-button.edit span{
    color: rgb(255 133 28);
}

.showroom-edit-button.edit svg{
    fill: rgb(255 133 28);
}

.showroom-edit-button.edit {
    background-color: rgba(255,133,28,0.2);
}

.showroom__edit-buttons-container {
    width: fit-content;
    display: flex;
    align-items: center;
}

.showroom__edit-buttons-container .showroom__edit-info-button {
    color: rgb(255 133 28); 
    font-size: 20px;
    margin-left: 5px;
    cursor: pointer;
    position: relative;
    z-index: 2;
    padding: 5px;
}

@media (max-width: 1615px) {
    .showroom-header__gallery-name h1 {
        font-size: 32px;
    }

    .showroom-header__accordeon .admin-accordeon-options__option {
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media (max-width: 1403px) {
    .showroom-header__accordeon .admin-accordeon__selected-option {
        font-size: 17px;
    }

    .showroom-header__accordeon .admin-accordeon-options__option {
        font-size: 16px;
    }

    .showroom-header__accordeon .admin-header-accordeon.active .admin-accordeon__options {
        padding-top: 45px;
    }

    .showroom-header__add .add-accordeon__icon {
        width: 39px;
        font-size: 24px;
    }

    .showroom-header__add .add-accordeon__options_option {
        font-size: 16px;
        margin-bottom:6px;
    }

    .showroom-header__add .add-accordeon.active .add-accordeon__options {
        width: 240px;
    }

    .showroom-header__add .add-accordeon__options-wrap {
        padding-left: 47px;
    } 

    .showroom-edit-button {
        padding: 0px 20px;
    }
    
    .showroom-edit-button span {
        font-size: 18px;
    }
    
    .showroom-edit-button svg {
        width: 15px;
        margin-right: 5px;
    }
}

@media (max-width: 1246px) {
    .showroom-header__gallery-name h1 {
        font-size: 27px;
    }  

    .showroom-header__accordeon .admin-header-accordeon {
        width: 70%;
    }
}

@media (max-width: 1169px) {
    .showroom-header__add, .showroom-header__accordeon {
        width: 30%;
    }
}

@media (max-width: 1028px) { 
    .showroom-header__gallery-name h1 {
        font-size: 24px;
    }

    .showroom-header__add .add-accordeon.active .add-accordeon__options {
        width: 210px;
    }

    .showroom-header__accordeon .admin-accordeon__selected-option {
        font-size: 16px;
    }

    .showroom-header__accordeon .admin-accordeon-options__option {
        font-size: 15px;
    }

    .showroom-header__add .add-accordeon__options_option {
        font-size: 15px;
    }

    .showroom-header__accordeon .admin-accordeon__selected-option {
        padding: 2px;
    }

    .showroom-header__add .add-accordeon__icon {
        width: 36px;
        font-size: 20px;
    }

    .showroom-header__add .add-accordeon__options-wrap {
        padding-left: 42px;
    } 
}

@media (max-width: 786px) { 
    .showroom__header {
        padding-top: 23px;
    }

    .showroom-header__add {
        position: relative; 
        left: 60px;
    }

    .showroom-header__add, .showroom-header__accordeon {
        width: 26%;
    }

    .showroom-header__accordeon .admin-header-accordeon {
        width: 100%;
    } 
}

@media (max-width: 641px) { 
    .showroom-header__accordeon .admin-accordeon__selected-option {
        font-size: 15px;
    }

    .showroom-header__accordeon .admin-accordeon-options__option {
        font-size: 14px;
    }

    .showroom-header__add .add-accordeon__options_option {
        font-size: 14px;
    }

    .showroom-header__add .add-accordeon.active .add-accordeon__options {
        width: 200px;
    }

    .showroom-header__gallery-name {
        display:none;
    }
}

@media (max-width: 569px) { 
    .showroom-header__add, .showroom-header__accordeon {
        width: 40%;
    }

    .showroom-header__accordeon .admin-header-accordeon {
        width: 60%;
    } 
}

@media (max-width: 489px) { 
    .showroom-header__accordeon .admin-header-accordeon {
        width: 80%;
    } 
}

@media (max-width: 400px) { 
    .showroom-header__add{
        width: 55%;
    }
}

@media (max-width: 380px) { 
    .showroom-header__accordeon .admin-header-accordeon {
        width: 90%;
    } 
}

.lupa-container {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 1050;
    display: flex;
    align-items: center;
}

.zoom-glassmorphism-background {
    background: rgba(0, 0, 0, 0.27);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(69.9px);
    --webkit-backdrop-filter: blur(12.9px);
}

.zoom-black-background {
    background-color: black;
}

.artworkZoom-container {
    width: 100%;
    height:100%;
    position: relative;
    display: flex;
    justify-content: center;
}

.openseadragon-container :focus { outline: none; }

#artworkZoom {
    width: 100%; 
    height: 100%;
}

.zoom-controls {
    position: absolute;
    bottom: 30px;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 20px;
    width: 240px;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color:white;
    font-size: 20px;
}

#artworkZoom__zoomIn, #artworkZoom__zoomOut, #artworkZoom__reset {
    cursor: pointer;
}

#artworkZoom__zoomOut {
    margin-top: 2px;
}

#artworkZoom__range {
    -webkit-appearance: none;
    width: 100px;
    height: 2px;
    border-radius: 5px;
    background: #FFF;
    outline: none;
    opacity: 1;
    -webkit-transition: .2s;
    transition: opacity .2s;
    margin-top: 2px;
}

#artworkZoom__range:hover {
    opacity: 1;
}

#artworkZoom__range::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    appearance: none;
    border-radius: 50%;
    background: #FFF;
    cursor: pointer;
  }
  
  #artworkZoom__range::-moz-range-thumb {
    border-radius: 50%;
    background: #FFF;
    width: 20px;
    height: 20px;
    appearance: none;
    cursor: pointer;
  }

  .x-icon {
    position: absolute;
    right: 2%;
    top: 1%;
    cursor: pointer;
}






.edit__changable-info {
    width: 100%;
}

.edit__change-pic-container {
    width: 130px;
    aspect-ratio: 1/1;
    border-radius: 50%;
    margin: 0 auto;
    position: relative;
    margin-top: 20px;
}

.edit__change-pic {
    width: 100%;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 50%;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    object-fit: cover;
}

.edit__change-pic.hide {
    display: none;
}

.edit__change-pic.loading {
    background: #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
}

.edit__change-pic.add {
    background: #242424;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    color: white;
}

.edit__change-pic.add .pic {
    font-size: 60px;
}

.edit__change-pic.add .plus {
    font-size: 22px;
    position: absolute;
    right: 22px;
    bottom: 27px;
    color: white;
    background: #ff4949;
    border-radius: 50%;
    padding: 3px;
}

.edit__choose-pic {
    width: 45px;
    aspect-ratio: 1/1;
    border-radius: 50%;
    background: var(--gradient-login);
    position: absolute;
    right: 0;
    bottom: -10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;  
}

.edit__choose-pic svg{
    width: 80%;
    fill: white;
}

.edit__change-forms-container {
    width: 90%;
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

.edit__change-gallery-info, .edit__change-personal-info {
    width: 42%;
    position: relative;
}

.edit__change-heading {
    width: 100%;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 26px;
    font-weight: 500;
    color: black;
    margin-bottom: 30px;
}

.edit__change-subheading {
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    font-size: 21px;
    font-weight: 600;
    margin-top: 0px;
    margin-bottom: 0px;
}

.edit__change-gallery-info-input-container {
    width: 100%;
}

.edit-input {
    width: 100%;
    border: none;
    outline: none;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-weight: 500;
    color: grey;
    padding-top: 10px;
    padding-bottom: 6px;
}

.edit-input-border {
    width: 100%;
    height: 3px;
    background: var(--gradient-login);
    margin-bottom: 26px;
}

.edit__change-input-group {
    width: 100%;
    display: flex;
}

.edit__change-select-container {
    width: 18%;
    margin-right: 15px;
}

.edit__change-select-container.phone {
    width: 30%;
    margin-right: 15px;
}

.edit__change-first-name-container {
    width: 60%;
}

.edit__change-phone-container {
    width: 42%;
}

.edit__change-subheading.underline {
    position: relative;
    width: fit-content;
    cursor: pointer;
    color: rgba(0,0,0,1);
    transition: color 300ms;
    margin-top: 29px;
}

.edit__change-subheading.underline:before {
    content: "";
    position: absolute;
    top: 90%;
    width: 100%;
    left: 0;
    height: 3px;
    background: var(--gradient-login);
}

.edit__change-subheading.underline:hover {
    color: rgba(0, 0, 0, 1);
}

@media (max-width: 1500px) {
    .edit__change-pic-container {
        width: 108px;
    }

    .edit__choose-pic {
        width: 38px
    }

    .edit__change-pic.add .pic {
        font-size: 51px;
    }
    
    .edit__change-pic.add .plus {
        font-size: 16px;
    }

    .edit__change-heading {
        font-size: 22px;
    }

    .edit__change-subheading {
        font-size: 18px;
    }

    .edit-input {
        font-size: 17px;
    }

    .edit__change-select-container .selected-option__option {
        font-size: 17px;
    }

    .edit__change-select-container .select-accordeon__selected-option {
        padding-bottom: 0px;
        padding-top: 2px;
    }

    .edit__change-select-container .select-accordeon__list-item {
        font-size: 15px;
    }

    .changable-submit__text {
        font-size: 18px;
    }

    .edit__change-select-container.phone {
        width: 36%;
    }

    .edit__change-select-container {
        width: 25%;
    }

    .edit__change-select-container .select-accordeon__selected-option-border {
        position: relative;
        top: -1px;
    }
}

@media (max-width: 1207px) {
    .edit__change-select-container.phone {
        width: 42%;
    }

    .edit__change-select-container {
        width: 30%;
    }

    .edit__change-phone-container {
        width: 53%;
    }

    .edit__change-first-name-container {
        width: 70%;
    }
}

@media (max-width: 1060px) {
    .edit__change-pic-container {
        width: 90px;
    }

    .edit__choose-pic {
        width: 32px
    }

    .edit__change-pic.add .pic {
        font-size: 45px;
    }

    .edit__change-pic.add .plus {
        font-size: 13px;
        right: 17px;
        bottom: 21px;
    }

    .edit__change-heading {
        font-size: 19px;
    }

    .edit__change-subheading {
        font-size: 16px;
    }

    .edit-input {
        font-size: 16px;
    }

    .edit__change-select-container .selected-option__option {
        font-size: 16px;
    }

    .edit__change-select-container .select-accordeon__selected-option {
        padding-bottom: 0px;
        padding-top: 0px;
    }

    .edit__change-select-container .select-accordeon__list-item {
        font-size: 14px;
    }

    .changable-submit__text {
        font-size: 16px;
    }

    .edit__change-gallery-info, .edit__change-personal-info {
        width: 46%;
    }

    .edit__change-forms-container {
        margin-top: 40px;
    }
} 

@media (max-width: 1000px) {
    .edit__change-gallery-info, .edit__change-personal-info {
        width: 100%;
    }

    .edit__change-forms-container {
        flex-wrap: wrap;
        width: 64%;
    }

    .edit__change-heading {
        margin-bottom: 20px;
    }

    .edit__change-subheading.underline {
        margin-top: 7px;
    }

    .edit__change-personal-info {
        margin-top: 36px;
    }

    .edit__change-select-container.phone {
        width: 30%;
    }

    .edit__change-select-container {
        width: 25%;
        padding-top: 2px;
    }

    .edit__change-phone-container {
        width: 67%;
    }

    .edit__change-first-name-container {
        width: 79%;
    }

    .edit__changable-submit {
        margin-top: 52px;
    }
}


@media (max-width: 786px) {
    .edit__change-forms-container {
        width: 80%;
    }

    .edit-input-border {
        height: 2px;
    }

    .edit__change-select-container .select-accordeon__selected-option-border {
        height: 2px;
    }
}

@media (max-width: 706px) {
    .edit__change-heading {
        font-size: 17px;
    }
} 

@media (max-width: 600px) {
    .edit__change-forms-container {
        width: 92%;
    }
}

@media (max-width: 477px) {
    .edit__change-forms-container {
        width: 85%;
    }

    .edit__change-select-container.phone {
        width: 40%;
    }
}

@media (max-width: 373px) {
    
    .edit__change-select-container.phone {
        width: 46%;
    }
}





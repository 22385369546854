.pin-view {
    background: var(--background);
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 9999;
    top: 0;
}

.pin-container {
    width: 30%;
    margin: 0 auto;
    min-height: 400px;
    min-width: 285px;
    height: 85%;
    max-width: 635px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.pin__close-container {
    width: 100%;
    display: flex;
    justify-content: center;
    font-family: 'Montserrat', sans-serif;
    color: var(--gallery-view-navbar-text);
    font-size: 28px;
    align-items: center;
    
}

.pin__x-icon {
    font-size: 25px;
    margin-right: 15px;
    padding-top: 2px;
}

.pin__x-icon i {
    background: -webkit-linear-gradient(90deg, rgba(250,185,50,1) 0%, rgba(224,99,113,1) 64%, rgba(181,66,139,1) 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.pin__close-options {
    cursor: pointer;
}

.pin__close-options i, .pin__close-text {
    transition: all 300ms;
}

.pin__close-text {
    opacity: 0.5;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.pin__close-options:hover i{
    transform: rotate(180deg);
}

.pin__close-options:hover .pin__close-text {
    opacity: 1;
}

.pin__text {
    margin-top: 40px;
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
    color: var(--gallery-view-navbar-text);
    text-align: center;
}

.pin__circles {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pin__circle {
    width: 35px;
    aspect-ratio: 1/1;
    border-radius: 50%;
    border: 1px solid var(--gallery-view-navbar-text);
    margin: 0 15px;
    transition: transform 300ms;
    transition: background 100ms;
    margin-top: 20px;
}

.pin__keyboard-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    justify-content: center;
}

.pin__keyboard-item {
    width: 27%;
    border-radius: 20px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 100ms;
    color: #FFF;
    font-size: 93px;
    font-weight: 500;
    max-width: 140px;
    max-height: 140px;
    aspect-ratio: 1/1;
    -webkit-text-stroke: 2px black;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.pin__delete-icon {
    display: flex;
}

.pin__keyboard-item i {
    font-size: 73px;
}

// .pin__keyboard-item:active {
//     transform: scale(0.85);
// }

.pressed {
    transform: scale(0.85);
}

.pin__circle-painted {
    background: var(--gallery-view-navbar-text);
    animation: pulse 300ms;
}

@keyframes pulse {
    0% {
        transform: scale(0.5);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

.pin__circle-clean {
    background: none;
}

.pin-shake {
    animation-name: pin-shake;
    animation-duration: 300ms;
    position: relative;
}

@keyframes pin-shake {
    0% {
        left:-10px;
    }
    16% {
        left:9px;
    }
    33% {
        left:-6px;
    }
    50% {
        left:5px;
    }
    66% {
        left:-2px;
    }
    83% {
        left:1px;
    }
    100% {
        left: 0px;
    }
}


@media (max-width: 1733px) {
    .pin__keyboard-item {
        font-size:72px;
    }

    .pin__keyboard-item i {
        font-size: 52px;
    }
}

@media (max-width: 1359px) {
    .pin__keyboard-item {
        font-size:54px;
    }

    .pin__keyboard-item i {
        font-size: 34px;
    }
}


@media (max-width: 1057px) {
    .pin__keyboard-item {
        width: 25%;
        margin: 8px;
    }
}

@media (max-height: 707px) {
    .pin__text {
        margin-top: 15px;
        margin-bottom: 2px;
        font-size: 18px;
    }

    .pin__circle {
        width: 30px;
        margin-top: 15px;
    }

    .pin__keyboard-container {
        margin-top: 20px;
    }

    .pin__x-icon {
        font-size: 19px;
        margin-right: 7px;
    }

    .pin__close-options {
        cursor: pointer;
    }

    .pin__close-container {
        font-size: 22px;
    }
}


@media (max-width: 787px) {
    .pin__text {
        margin-top: 15px;
        margin-bottom: 2px;
        font-size: 17px;
    }

    .pin__circle {
        width: 25px;
        margin-top: 20px;
    }

    .pin__x-icon {
        font-size: 19px;
        margin-right: 7px;
    }

    .pin__close-options {
        cursor: pointer;
    }

    .pin__close-container {
        font-size: 22px;
    }
}
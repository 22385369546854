.swiper.suggestions-slider {
    width: 100%;
    height: 100%;
}

.suggestions-slider__item {
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
}

.suggestions-slider .swiper-button-prev, .suggestions-slider .swiper-button-next {
    background: #242424;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.suggestions-slider .swiper-button-next.swiper-button-disabled, .suggestions-slider .swiper-button-prev.swiper-button-disabled {
    pointer-events: all;
}

.suggestions-slider .swiper-button-prev::after, .suggestions-slider .swiper-button-next::after {
    font-size: 16px !important;
    color: white;
}
@media (max-width: 600px) {
    .suggestions-slider .swiper-button-prev, .suggestions-slider .swiper-button-next {
        display: none;
    }

}
.edit__business-info {
    width: 100%;
}

.business__heading {
    position: relative;
    width: fit-content;
    color: rgba(0,0,0,1);
    transition: color 300ms;
    margin-top: 55px;
    margin: 0 auto;
    font-family: 'Montserrat', sans-serif;
    font-size: 26px;
    font-weight: 600;
    padding-top: 50px;
    margin-bottom: 45px;
}

.business__heading:before {
    content: "";
    position: absolute;
    top: 93%;
    width: 100%;
    left: 0;
    height: 3px;
    background: var(--gradient-login);
}

.business-info__wrap {
    width: 65%;
    margin: 0 auto;
    max-width: 600px;
}

.business-info__subheading {
    font-family: 'Montserrat', sans-serif;
    font-size: 22px;
    font-weight: 600;
    color: black;
    margin-bottom: 3px;
    padding-left: 8px;
}

.business-info__info-section {
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    font-size: 22px;
    font-weight: 500;
    color: gray;
    padding: 8px;
    box-shadow: 0 0 6px #ccc;
    border-radius: 5px;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    margin-bottom: 30px;
    overflow-wrap: break-word;
}

.business-info__group-item {
    width: 43%;
}

.business-info__group {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.business-info__warning-container {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
}

.business-info__warning-text {
    margin-top: 15px;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-weight: 500;
    color: black;
}

.business-info__warning-contact-us {
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-weight: 500;
    color: black;
    cursor: pointer;
    position: relative;
    width: fit-content;
}

.business-info__warning-contact-us:before {
    content: "";
    position: absolute;
    top: 90%;
    width: 100%;
    left: 0;
    height: 3px;
    background: var(--gradient-login);
}

@media (max-width: 1629px) {
    .business__heading {
        font-size: 22px;
    }

    .business-info__subheading {
        font-size: 19px;
    }

    .business-info__info-section {
        font-size: 18px;
    }

    .business-info__warning-text, .business-info__warning-contact-us {
        font-size: 18px;
    }
}

@media (max-width: 1219px) {
    .business__heading {
        font-size: 19px;
    }

    .business-info__subheading {
        font-size: 17px;
    }

    .business-info__info-section {
        font-size: 16px;
    }

    .business-info__warning-text, .business-info__warning-contact-us {
        font-size: 17px;
    }

    .edit__back-to-changable {
        font-size: 22px;
    }
}


@media (max-width: 961px) {
    .business-info__warning-container {
        width: 65%;
    }  
}

@media (max-width: 817px) {
    .business__heading {
        font-size: 18px;
    }

    .business-info__subheading {
        font-size: 16px;
    }

    .business-info__info-section {
        font-size: 16px;
    }

    .business-info__warning-text, .business-info__warning-contact-us {
        font-size: 16px;
    }

    .edit__back-to-changable {
        font-size: 22px;
    }
}

@media (max-width: 477px) {
    .business-info__wrap {
        width: 77%;
    }

    .business-info__warning-container {
        width: 77%;
    } 

    .business__heading {
        font-size: 17px;
    }

    .business-info__subheading {
        font-size: 16px;
    }

    .business-info__info-section {
        font-size: 16px;
    }

    .business-info__warning-text, .business-info__warning-contact-us {
        font-size: 15px;
    }

    .edit__back-to-changable {
        font-size: 22px;
        top: 20px;
        left: 14px;
    }

    .business__heading:before {
        top: 95%;
    }
}



.register-success {
    width: 100%;
    background-color: white;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.register-success__content {
    width: 50%;
    aspect-ratio: 2/1;
    border: 3px solid green;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.register-sucess__text {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: green;
    font-family: 'Montserrat', 'sans-serif';
}
[data-theme="dark"]  {
    --neumorphism-gallery-view:4px 4px 7px #121212,
    -4px -4px 7px #363636;
    --gallery-view-printing-name: #FFFFFF;
}

[data-theme="light"] {
    --neumorphism-gallery-view: 5px 5px 7px #77787c,
    -5px -5px 7px #ffffff;
    --gallery-view-printing-name: #242424;
}

.artwork-card {
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 30px;
    box-shadow: var(--neumorphism-gallery-view);
}

.artwork__image {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.artwork__artwork.true {
    opacity: 0.6;
}

.edit-showroom__options {
    width: fit-content;
    height: 40px;
    display: flex;
    background: rgba(255, 133, 28, 0.7);
    position: absolute;
    align-items: center;
    z-index: 2;
    bottom: 10px;
    border-radius: 20px;
    justify-content: center;
    padding: 0 20px;
    border: 2px solid rgb(255, 133, 28)
}

.edit-showroom__wrap {
    width: fit-content;
}

.edit-showroom__options span {
    color: white;
    font-size: 17px;
    cursor: pointer;
    padding: 0 10px;
    font-weight: 600;
    font-family: 'Montserrat';
    border-right: 2px solid white;
}

.edit-showroom__options span.disabled {
    cursor: default;
    opacity: 0.3;
}

.edit-showroom__options span:last-child {
    border-right: none;
}

.artwork__image.artwork__image-template {
    aspect-ratio: 1/1;
    background: #414141;
}

.artwork__artwork {
    width: 100%;
}

.artwork__info {
    width: 100%;
    background-color: transparent;
    padding: 10px;
    padding-top: 0px;
}

.artwork__name {
    font-size: 1.5rem;
    font-style: italic;
    color: var(--gallery-view-printing-name);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-weight: 500;
}

.artwork-details {
    color: var(--gallery-view-printing-details);
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 19px;
}

.artwork__price {
    margin-left: 5px;
}

@media (max-width: 1350px) {
    .artwork-details {
        font-size: 17px;
    }
    
    .artwork__name {
        font-size: 20px;
    }   

    .edit-showroom__options {
        height: 30px;
        padding: 0 10px;
    }
}

@media (max-width: 1230px) {
    .artwork-details {
        font-size: 16px;
    }
}

@media (max-width: 1200px) {
    .artwork-card {
        margin-bottom: 18px;
    } 

    .edit-showroom__options span {
        font-size: 16px;
    }
}

@media (max-width: 1020px) {
    
    .artwork__name {
        font-size: 19px;
    }  

    .artwork-details {
        font-size: 15px;
    } 
}

@media (max-width: 800px) {
    
    .artwork__name {
        font-size: 18px;
    }  
}

@media (max-width: 670px) {
    .edit-showroom__options span {
        font-size: 15px;
    }
}

@media (max-width: 550px) {
    
    .artwork__name {
        font-size: 21px;
    }  

    .artwork-details {
        font-size: 17px;
    } 
}


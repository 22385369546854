.loading-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-container.black {
    background: #242424;
}

.loading-container.white {
    background: #fff;
}


.register-container {
    width: 100%;
    min-height: 100vh;
    background: #fff;
    padding-top: 45px;
}

.register__heading {
    width: 100%;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 26px;
    font-weight: 600;
    color: #be00bf;
}

.register__wrap {
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 50px;
}

.register__gallery-info, .register__personal-info {
    width: 47%;
}

.register__subheading {
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    font-weight: 600;
    color: #be00bf;
    margin-bottom: 20px;
}

.register-inputs__wrap {
    width: 100%;
    padding-left: 10px;
    padding-bottom: 40px;
}

.register__input-label {
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    font-size: 21px;
    font-weight: 500;
    color: black;
    margin-bottom: 10px;
    padding-left: 10px;
}

.register__input {
    width: 100%;
    border: none;
    outline: none;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    color: gray;
    font-weight: 500;
    padding: 8px;
    padding-left: 10px;
    box-shadow: 0 0 6px #ccc;
    margin-bottom: 15px;
}

.register__input-group {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.register__input-group-item {
    width: 47%;
}

.register__input-group-accordeon {
    width: 25%;
    border: none;
    outline: none;
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-weight: 500;
    box-shadow: 0 0 6px #ccc;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
}

.register__input-group-accordeon-right {
    width: 70%;
}

.register__input-group.phone .register__input-group-accordeon {
    width: 36%;
}

.register__input-group.phone .register__input-group-accordeon-right {
    width: 58%;
}

.register__submit-button {
    width: fit-content;
    margin: 0 auto;
    display: block;
    border: 2px solid #be00bf;
    background: rgba(191, 5, 186, 0.2);
    border-radius: 20px;
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    color: #be00bf;
    padding: 8px;
    padding-left: 10px;
    padding-right: 10px;
}

.register__submit-button.loading {
    opacity: 0.3;
    display: flex;
    align-items: center;
}

.register__submit-button.loading span {
    margin-left: 10px;

}

.register__submit-button.loading .spinner-grow {
    width: 1.5rem;
    height: 1.5rem;
}

.register__input-error-container {
    width: 100%;
    margin-top: -8px;
    margin-bottom: 10px;
    display: none;
}

.register__input-error-container.show {
    display: block;
}

.register__input-error-icon, .register__input-error-text {
    color: #d93025;
}

.register__input-error-icon {
    margin-right: 5px;
    font-size: 15px;
}

.register__input-error-text {
    font-size: 15px;
    font-weight: 600;
    font-family: 'Montserrat', 'sans-serif';
}

.register__checkboxes {
    width: 100%;
    margin-top: 10px;
}

.register__checkbox-container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
}

.register__checkbox {
    min-width: 20px;
    aspect-ratio: 1/1;
    height: fit-content;
    cursor: pointer;
    border: 2px solid #8080807a;
    position: relative;
    display: flex;
    justify-content: center;
    top: 1px;
    align-items: center;
}

.register__checkbox-container.checked .register__checkbox {
    background: var(--gradient-login);
    border: none;
}

.register__checkbox-container.checked .register__checkbox-label {
    opacity: 1;
}

.register__checkbox svg {
    position: absolute;
    width: 85%;
    aspect-ratio: 1/1;
    fill:white;
}


.register__checkbox-label {
    margin-left: 8px;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    color: #242424;
    margin-bottom: 0px;
    opacity: 0.6;
}

@media (max-width: 1170px) {
    .register__heading {
        font-size: 21px;
    }

    .register__subheading {
        font-size: 20px;
    }

    .register__input-label {
        font-size: 18px;
    }

    .register__input {
        font-size: 17px;
    }

    .register__input-group-accordeon .selected-option__option {
        font-size: 17px;
    }

    .register__input-group-accordeon .select-accordeon__list-item {
        font-size: 15px;
    }

    .register__submit-button {
        font-size: 17px;
    }

    .register__input-group-accordeon .select-accordeon__selected-option {
        padding-top: 0px;
        padding-bottom: 0px;
    }
}

@media (max-width: 894px) {
    .register__heading {
        font-size: 19px;
    }

    .register__subheading {
        font-size: 18px;
    }

    .register__input-label {
        font-size: 16px;
    }

    .register__input {
        font-size: 15px;
    }

    .register__input-group-accordeon .selected-option__option {
        font-size: 15px;
    }

    .register__input-group-accordeon .select-accordeon__list-item {
        font-size: 13px;
    }

    .register__submit-button {
        font-size: 15px;
    }

    .register__input-group.phone .register__input-group-accordeon {
        width: 42%;
    }

    .register__input-group.phone .register__input-group-accordeon-right {
        width: 53%;
    }

    .register__input-group-accordeon {
        width: 31%;
    }

    .register__input-group-accordeon-right {
        width: 64%;
    }

    .register__checkbox-label {
        font-size: 14px;
    }

    .register__checkbox {
        min-width: 18px;
    }
}

@media (max-width: 772px) {

    .register__gallery-info, .register__personal-info {
        width: 100%;
    }

    .register__wrap {
        flex-wrap: wrap;
        padding-bottom: 110px;
    }

    .register__input-group-accordeon .select-accordeon__list-item {
        font-size: 14px;
    }    
}

@media (max-width: 772px) {
    .register__wrap {
        width: 90%;
    }

    .register__input-label {
        font-size: 15px;
    }

    .register__input {
        font-size: 14px;
    }

    .register__input-group-accordeon .selected-option__option {
        font-size: 14px;
    }

    .register__input-group-accordeon .select-accordeon__list-item {
        font-size: 13px;
    }
}
.wishlist__clear-button-center {
    width: 100%;
    padding-bottom: 80px;
}

.wishlist__clear-button {
    width: fit-content;
    padding: 8px;
    border-radius: 5px;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 16px;
    font-weight: 600;
    color: #333;
    margin: 0 auto;
    cursor: pointer;
}

@media (max-width: 830px) {

    .wishlist__clear-button {
        font-size: 15px;
        padding: 6px;
    }
}
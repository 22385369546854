.login-center {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--background-login);
    font-family: 'Montserrat', sans-serif;
    position: relative;
    height: 100%;
}

.login-container {
    width: 41%;
    min-height: 500px;
    border-radius: 50px;
    background: #242424;
    padding: 30px;
}

.login-form {
    margin-top: 40px;
}

.login-form__image {
    width: 80%;
    margin: 0 auto;
    display: block;
}

.email-input--login {
    margin-top: 52px !important;
}

.email-input, .password-input {
    width: 80%;
    height: 30px;
    margin: 0 auto;
    position: relative;
}

.login-form__border {
    width: 80%;
    height: 2px;
    background: var(--gradient-login);
    margin:0 auto;
    margin-bottom: 30px;
    margin-top: 1px;
}

.email-input__email, .password-input__password {
    width: 100%;
    height: 100%;
    padding-left: 10px;
    position: relative;
    border: none;
    z-index: 2;
    transition: all 300ms;
    font-size: 19px;
    background: transparent;
}

.input-animation:focus {
    outline:none;
    background: var(--background-login);
    color: white;
}

.login-container__animation.active{
    color: #707070;
    z-index: 1;
    top: -20px;
    font-size: 17px;
    opacity: 1;
}

.login-container__animation {
    position: absolute;
    top: 1px;
    left: 5px;
    z-index: 1;
    transition: all 300ms;
    color: #707070;
    font-size: 19px;
    opacity: 0.5;
}

.input-with-content {
    background: var(--background-login) !important;
    color: white;
}

.login-form__submit {
    width: 80%;
    color: white;
    border-color: transparent;
    border-width: 2px;
    border-image-slice: 1;
    border-image-source: var(--gradient-login);
    background-repeat: no-repeat;
    margin-top: 15px;    
    transition: all 0.5s ease-in-out;
    border-radius: 0px;
    background: transparent;
    font-size: 20px;
    padding: 10px;
    margin: 0 auto;
    display: block;
}

.login-form__submit:hover {
    background-color: transparent;
    background-image: var(--gradient-login);
    cursor: pointer;
    border: 2px solid transparent;
    color: white;   
    border-radius: 0!important;
    background-size: 102% 102%;
    background-repeat: no-repeat;
}

.info-error-container {
    width: 80%;
    margin: 0 auto;
    margin-top: -20px;
    margin-bottom: 40px;
    color: rgb(167, 8, 8);
    display: none;
}

.info-error-container__icon {
    margin-right: 7px;
}

.info-error-container__text-error {
    font-size: 14px;
}

.login-form-options {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    font-size: 18px;
}

.login-form-options__forgot-password {
    color:white;
}

.login-form-options__forgot-password:hover{
    text-decoration: underline;
    cursor: pointer;
}

.remember-me {
    color: white;
}

.remember-me__checkbox {
    margin-right: 5px;
    cursor: pointer;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0px 1000px var(--background-login) inset !important;
    -webkit-text-fill-color: white !important;
    -webkit-transition: none;
}


@media (max-width: 1300px) {
    .login-container {
        width: 50%;
        padding-left: 0px;
        padding-right: 0px;
    }
}

@media (max-width: 850px) {
    .login-container {
        width: 70%;
    }
}

@media (max-width: 600px) {
    .login-container {
        width: 87%;
    }

    .remember-me, .login-form-options__forgot-password{
        font-size: 14px;
    }
}






[data-theme="dark"] {
    --cart-product-inset: inset 5px 5px 3px #171717, inset -5px -5px 2px #313131;  
    --cart-product-details-color: var(--gray-dark-mode);
}

[data-theme="light"] {
    //TO DO CART-PRODUCT INSET AND colors and stuff
}


.cart-product {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 22px;
    box-shadow: var(--cart-product-inset);
    //border: 0.75px solid grey;
    margin-bottom: 22px;
    position: relative;
}


.cart-product.remove {
    transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
    opacity: 0;
    transform: translateX(-200px);
}
.cart-product__image {
    width: 40%;
    box-shadow: var(--neumorphism-gallery-view);
}

.cart-product__image img {
    width: 100%;
}

.cart-product__details {
    width: 57%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.cart-product__name {
    color: var(--gallery-view-navbar-text);
    font-size: 32px;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: -7px;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.cart-product__author {
    color: var(--cart-product-details-color);
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    width: 100%;
    margin-top: 3px;
}

.cart-product__dimension-type, .cart-product__price-info {
    width: 100%;
    margin-top: 25px;
    font-weight: 500;
}

.cart-product__dimension, .cart-product__type, .cart-product__price {
    color: var(--cart-product-details-color);
    font-family: 'Montserrat', sans-serif;
    font-size: 22px;
    margin-bottom: 0px;
    font-weight: 500;
}

.x-icon-checkout {
    color: var(--cart-product-details-color);
    position: absolute;
    text-align: end;
    right: 0;
    width: 50px;
    padding-right: 10px;
    transition: all 300ms;
}

.x-icon-checkout svg {
    width: 100%;
    opacity: 0.4;
    fill: grey;
    transition: all 300ms;
    cursor: pointer;
    opacity: 0.4;
}

.x-icon-checkout svg:hover {
    opacity: 1;
    transform: rotate(180deg);
}

.cart-product__quantity {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.cart-product__quantity-button {
    width: 200px;
    height: 50px;
    border: 2px solid grey;
    border-radius: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    color: white;
}

.quantity-button__minus, .quantity-button__plus {
    font-size: 29px;
    padding: 15px;
    cursor: pointer;
    transition: opacity 0.3s;
}

.quantity-button__quantity {
    font-weight: 500;
    font-size: 25px;
}

.quantity-button__minus.disabled, .quantity-button__plus.disabled {
    opacity: 0.4;
    pointer-events: none;
}

@media (max-width: 1596px) {
    .cart-product__name {
        font-size: 26px;
    }

    .cart-product__author {
        font-size: 20px;
    }

    .cart-product__dimension, .cart-product__type, .cart-product__price {
        font-size: 19px;
    }

    .cart-product__quantity-button {
        width: 150px;
        height: 38px;
    }

    .quantity-button__quantity {
        font-size: 20px;
    }

    .quantity-button__minus, .quantity-button__plus {
        font-size: 20px;
    }
}


@media (max-width: 1368px) {
    .cart-product__dimension-type, .cart-product__price-info {
        margin-top: 7px;
    }
}

@media (max-width: 1160px) {
    .cart-product__name {
        font-size: 23px;
    }

    .cart-product__author {
        font-size: 17px;
    }

    .cart-product__dimension, .cart-product__type, .cart-product__price {
        font-size: 16px;
    }

    .cart-details {
        width: 68%;
    }

    .cart-product {
        padding: 17px;
    }
}

@media (max-width: 968px) {
    .cart-product__quantity-button {
        width: 120px;
        height: 30px;
    }

    .quantity-button__quantity {
        font-size: 16px;
    }

    .quantity-button__minus, .quantity-button__plus {
        font-size: 16px;
    }
}



@media (max-width: 908px) {
    .x-icon-checkout {
        width: 42px;
    }
}

@media (max-width: 783px) {
    .cart-product__name {
        font-size: 18px;
        margin-bottom: -2px;
    }

    .cart-product__author {
        font-size: 15px;
    }

    .cart-product__dimension, .cart-product__type, .cart-product__price {
        font-size: 14px;
    }

    .cart-details {
        width: 83%;
    }

    .cart-product__image {
        width: 47%;
    }

    .cart-product__details {
        width: 50%;
    }
}

@media (max-width: 655px) {
    .cart-product {
        flex-wrap: wrap;
    }

    .cart-details {
        width: 87%;
    }

    .cart-product__image {
        width: 100%;
    }

    .cart-product__details {
        width: 100%;
        padding: 20px;
        padding-left: 5px;
        padding-bottom: 5px;
        padding-right: 5px;
    }

    .cart-product {
        padding: 17px;
    }

    .cart-product__name {
        font-size: 18px;
        margin-bottom: -2px;
    }

    .cart-product__author {
        font-size: 16px;
    }

    .cart-product__dimension, .cart-product__type, .cart-product__price {
        font-size: 15px;
    }
}







.profile-navigation-options {
    width: 100%;
    border-top: 1px solid grey;
    padding-top: 12px;
    margin-top: 35px;
    padding-left: 5%;
    padding-right: 4%;
}

.profile-navigation-options__link {
    color: #000000;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
    padding: 4px;
    border-radius: 20px;
    cursor: pointer;
    font-weight: 500;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 10px;
    border: 2px solid transparent;
}

.profile-navigation-options__link:hover {
    color: black;
}

.active.dashboard {
    background: rgba(255, 190, 46, 0.1);
    border: 2px solid rgba(255, 190, 46, 1);
    color: rgba(255, 190, 46, 1);
}

.active.dashboard svg{
    stroke: rgba(255, 190, 46, 1) !important;
}

.active.showroom {
    background: rgba(242, 152, 75, 0.1);
    border: 2px solid rgba(242, 152, 75, 1);
    color: rgba(242, 152, 75, 1);
}

.active.showroom svg{
    stroke: rgba(242, 152, 75, 1) !important;
}

.active.orders {
    background: rgba(228, 114, 104, 0.1);
    border: 2px solid rgba(228, 114, 104, 1);
    color: rgba(228, 114, 104, 1);
}

.active.orders svg{
    stroke: rgba(228, 114, 104, 1) !important;
}

.active.notifications {
    background: rgba(214, 76, 131, 0.1);
    border: 2px solid rgba(214, 76, 131, 1);
    color: rgba(214, 76, 131, 1);
}

.active.notifications svg{
    stroke: rgba(214, 76, 131, 1) !important;
}

.active.referrals {
    background: rgba(201, 38, 160, 0.1);
    border: 2px solid rgba(201, 38, 160, 1);
    color: rgba(201, 38, 160, 1);
}

.active.referrals svg{
    stroke: rgba(201, 38, 160, 1) !important;
}

.active.support {
    background: rgba(190, 0, 191, 0.1);
    border: 2px solid rgba(190, 0, 191, 1);
    color: rgba(190, 0, 191, 1);
}

.active.support svg{
    stroke: rgba(190, 0, 191, 1) !important;
}

.profile-navigation-link__container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-navigation__icon {
    width: 29px;
    margin-right: 8px;
}

.profile-navigation__icon svg {
    width: 100%;
    overflow: inherit;
    position: relative;
}

.profile-navigation-options__accordeon {
    width: 90%;
    margin-left: auto;
    margin-bottom: 8px;
    overflow: hidden;
}

.profile-navigation__suboption {
    display: block;
    width: fit-content;
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    color: black;
    margin-bottom: 3px;
}

.profile-navigation__suboption:hover {
    color: black;
}

.active.showroom-sub {
    background: rgba(242, 152, 75, 0.1);
    color: rgba(242, 152, 75, 1);
    font-weight: 600;
    border-radius: 20px;
}

.active.support-sub {
    background: rgba(190, 0, 191, 0.1);
    color: rgba(190, 0, 191, 1);
    font-weight: 600;
    border-radius: 20px;
}

@media (max-width: 1726px) {
    .profile-navigation-options__link {
        font-size: 17px;
    }

    .profile-navigation__icon {
        width: 25px
    }
}

@media (max-width: 1180px) {
    .profile-navigation-options__link {
        font-size: 16px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .profile-navigation__icon {
        width: 23px
    }

    .profile-navigation__suboption {
        font-size: 14px;
    }
}

@media (max-width: 894px) {
    .profile-navigation-options__link {
        font-size: 15px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .profile-navigation__icon {
        width: 23px
    }
}

@media (max-width: 786px) {
    .profile-navigation-options__link {
        font-size: 16px;
    }

    .profile-navigation__suboption {
        font-size: 15px;
    }

    .profile-navigation__edit-h1 {
        font-size: 16px;
    }
}





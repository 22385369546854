.search-filter-container {
    min-width: 180px;
    max-width: 180px;
    margin-right: 25px;
}

.search-filter__option-outter {
    min-width: 100%;
    max-width: 100%;
    padding: 3px;
    cursor: pointer;
}

.search-filter__option-inner {
    min-width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 23px;
    background: var(--background);
    color: #FFF;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    transition: background 200ms;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.search-filter__option-inner:hover, .search-filter-container.active .search-filter__option-inner {
    background: transparent;
}



//ACCORDEON STYLES

.search-filter__options {
    width: 100%;
    max-height: 0;
    transition: max-height 300ms;
    color: white;
    font-size: 16px;
    overflow: hidden;
    font-family: 'Montserrat', sans-serif;
    scrollbar-width: thin;
    scrollbar-color: #FFF #353535;
}

//WEBKIT SCROLLBAR STYLES
.search-filter__options::-webkit-scrollbar {
    width: 0.5em;
}

.search-filter__options::-webkit-scrollbar-track {
    background: #353535;
}

.search-filter__options::-webkit-scrollbar-thumb {
    background: #FFF;
    border-radius: 20px;
}

.search-filter-container.active .search-filter__options {
    max-height: 250px;
    overflow: auto;
    animation: delay-overflow 500ms;
}

@keyframes delay-overflow {
    from { overflow: hidden; }
  }

.search-filter__options-item {
    cursor: pointer; 
    padding-left: 8px;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    transition: background 200ms;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-bottom: 0px;
}

.search-filter__options-item:hover, .search-filter__options-item.active {
    background: #464646;
}


.search-filter__options-item:nth-child(1) {
    margin-top: 8px;
}


@media (max-width: 1337px) {
    .search-filter-container {
        min-width: 155px;
        max-width: 155px;
        margin-right: 21px;
    }

    .search-filter__option-inner {
        font-size: 20px;
    }
}

@media (max-width: 1141px) {
    .search-filter-container {
        min-width: 136px;
        max-width: 136px;  
    }

    .search-filter__option-inner {
        font-size: 18px;
    }

    .search-filter__options {
        font-size: 14px;
    }
}

@media (max-width: 1023px) {
    .search-filters-container {
        width: 67%;
        flex-wrap: wrap;
    }

    .search-filter-container {
        margin-bottom: 25px;  
    }

}

@media (max-width: 820px) {
    .search-filters-container {
        width: 85%;
    }
}

@media (max-width: 665px) {
    .search-filters-container {
        width: 100%;
    }

    .search-filter__option-inner {
        font-size: 16px;
    }
}

@media (max-width: 550px) {
    .search-filters-container {
        width: 70%;
    }

    .search-filter-container {
        min-width: 150px;
        max-width: 150px; 
    }

    .search-filter-container {
        margin-bottom: 14px;  
    }


}




    

.modal {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    padding-left: 0px !important;
}

.modal::-webkit-scrollbar {
    display: none;
}


.custom-popup__default-class {
    max-width: none;
    margin-left: auto;
    margin-right: auto;
}

.custom-popup__default-class .modal-content {
    border: none;
    border-radius: 10px;
}

.custom-popup__default-class .modal-header {
    width: fit-content;
    position: absolute;
    right: 12px;
    top: 10px;
    display: block;
    padding: 0px;
    border: none;
}

.modal-backdrop.false {
    opacity: 0 !important;
}

.custom-popup__content {
    width: 100%;
}

@media (max-width: 500px) {
    div.custom-popup__default-class {
        width: 96%;
    }
}


.remove-artwork__popup {
    width: 90%;
    max-width: 900px !important;
}

.remove-artwork__content {
    width: 90%;
    margin: 0 auto;
    padding-bottom: 50px;
    padding-top: 16px;
}
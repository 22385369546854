.new-artwork {
    width: 100%;
    position: relative;
    padding-top: 30px;
}

.new-artwork__back-link {
    color: black;
    font-size: 30px;
    position: absolute;
    top: 10px;
    left: 40px;
    padding: 10px;
}

.new-artwork__back-link:hover {
    color: black;
}

.new-artwork__heading {
    width: 100%;
    color: rgb(255, 133, 28);
    font-family: 'Montserrat', 'sans-serif';
    font-size: 22px;
    font-weight: 600;
    text-align: center;
}

.new-artwork__wrap {
    width: 80%;
    max-width: 1000px;
    margin: 0 auto;
    margin-top: 20px;
}

.new-artwork__subheading {
    width: 100%;
    color: rgb(255, 133, 28);
    font-family: 'Montserrat', 'sans-serif';
    font-size: 21px;
    font-weight: 600;
}

.new-artwork__label {
    width: 100%;
    color: rgb(255, 133, 28);
    font-family: 'Montserrat', 'sans-serif';
    font-size: 19px;
    font-weight: 500;
    color: black;
    margin: 0;
    padding-left: 15px;
    margin-bottom: 10px;
}

.new-artwork__form {
    width: 100%;
}

.new-artwork__input {
    width: 100%;
    border: none;
    outline: none;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    color: gray;
    font-weight: 500;
    padding: 6px;
    padding-left: 10px;
    box-shadow: 0 0 6px #ccc;
    margin-bottom: 20px;
    border-radius: 5px;
}

.new-artwork__input.textarea {
    resize: none;
    height: 150px;
}

.new-artwork__group {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.new-artwork__group-item {
    width: 200px;
    margin-right: 30px;
}

.new-artwork__group-item:nth-child(4) {
    margin-right: 0px;
}

.new-artwork__group-item-list {
    width: 100%;
    aspect-ratio: 5/4;
    padding: 6px;
    box-shadow: 0 0 6px #ccc;
    border-radius: 5px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #353535 #ddd;
    margin-bottom: 10px;
}

//WEBKIT SCROLLBAR STYLES
.new-artwork__group-item-list::-webkit-scrollbar {
width: 0.6em;
}

.new-artwork__group-item-list::-webkit-scrollbar-track {
background: #ddd;
}

.new-artwork__group-item-list::-webkit-scrollbar-thumb {
background: #353535;
border-radius: 20px;
}

.new-artwork__group-item-list__item {
    width: fit-content;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    color: gray;
    font-weight: 500;
    margin: 0;
    cursor: pointer;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}


.new-artwork__group-item-list__item.selected {
    color: rgb(255, 133, 28);
    background: rgba(255, 133, 28, 0.2);
    border-radius: 12px;
}

.new-artwork__technique-input-container {
    width: 100%;
    display: none;
}

.new-artwork__technique-input-container.show {
    display: block;
}

.new-artwork__group.year {
    width: 100%;
    justify-content: left;
}
.new-artwork__group-year {
    width: 150px;
    margin-right: 70px;
}

.new-artwork-size__inputs-container {
    width: 100%;
    display: flex;
}

.new-artwork-size__inputs-container span {
    padding-top: 5px;
    font-size: 17px;
    color: black;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    margin-left: 8px;
    margin-right: 8px;
}

.new-artwork-size__input {
    width: 100px;
}

.new-artwork-price__container {
    width: 150px;
}

.new-artwork__tag-container {
    width: 100%;
    display: flex;
    margin-bottom: 30px;
}

.new-artwork__tag-displayer {
    width: fit-content;
}

.new-artwork__price-tag__container {
    width: fit-content;
    padding: 10px;
    padding-left: 0px;
    border-radius: 5px;
    padding-top: 20px;
    padding-bottom: 20px;
    box-shadow: 0 0 6px #ccc;
    background: #242424;
}

.new-artwork__tag-color-picker {
    margin-left: 31px;
    position: relative;
    top: 32px;
}

//REACT COLOR PACKAGE DOESNT HAVE CLASSES FOR THE ELEMENTS THAT'S WHY WE HAVE TO EDIT IT THIS WAY
.new-artwork__tag-color-picker .flexbox-fix:nth-child(2) {
    display: none !important;
}

.new-artwork__tag-color-picker .flexbox-fix:nth-child(1) > div:nth-child(1) {
    display: none !important;
}

.new-artwork__tag-color-picker .flexbox-fix:nth-child(1) > div:nth-child(2) > div:nth-child(2) {
    display: none !important;
}

.new-artwork__tag-color-picker .flexbox-fix:nth-child(1) > div:nth-child(2) > div:nth-child(1) {
    margin-bottom: 0px !important;
}

.new-artwork__packed-weight {
    width: 161px;
    display: flex;
}

.new-artwork__packed-weight span {
    margin-left: 10px;
    padding-top: 5px;
    font-size: 17px;
    color: black;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
}


.new-artwork__request-limited {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 30px;
}

.new-artwork__request-limited-text {
    color: rgb(255, 133, 28);
    font-family: 'Montserrat', 'sans-serif';
    font-size: 18px;
    font-weight: 600;
}

.new-artwork__request-limited-check {
    width: 18px;
    aspect-ratio: 1/1;
    margin-left: 11px;
    cursor: pointer;
}

.new-artwork__audio-container {
    width: 100%;
    margin-bottom: 20px;
}

@media (max-width: 1414px) {
    .new-artwork__heading {
        font-size: 20px;
    }

    .new-artwork__subheading {
        font-size: 19px;
    }

    .new-artwork__label {
        font-size: 17px;
    }

    .new-artwork__input {
        font-size: 16px;
    }

    .new-artwork__group-item-list__item, .new-artwork-size__inputs-container span, .new-artwork__packed-weight span  {
        font-size: 16px;
    }

    .new-artwork__back-link {
        top: 3px;
        left: 33px;
        font-size:24px;
    }
}

@media (max-width: 1308px) {
    .new-artwork__group-item {
        width: 180px;
    }

    .new-artwork__wrap {
        width: 90%;
    }

    .new-artwork__request-limited-text {
        font-size: 17px;
    }

    .new-artwork__request-limited-check {
        width: 17px;
    }
}

@media (max-width: 955px) {
    .new-artwork__heading {
        font-size: 19px;
    }

    .new-artwork__subheading {
        font-size: 18px;
    }

    .new-artwork__label {
        font-size: 16px;
    }

    .new-artwork__input {
        font-size: 16px;
    }

    .new-artwork__group-item-list__item, .new-artwork-size__inputs-container span, .new-artwork__packed-weight span  {
        font-size: 15px;
    }

    .new-artwork__back-link {
        top: 3px;
        left: 33px;
        font-size:20px;
    }

    .new-artwork__group-item {
        margin-right: 15px;
    }
}

@media (max-width: 786px) {
    .new-artwork__heading {
        font-size: 18px;
    }

    .new-artwork__subheading {
        font-size: 17px;
    }

    .new-artwork {
        padding-top: 80px;
    }

    .new-artwork__back-link {
        top: 65px;
        left: 22px;
    }

    .new-artwork__tag-color-picker {
        margin-left: 23px;
    }

    .new-artwork__tag-color-picker .chrome-picker {
        width: 176px;
    }
}


@media (max-width: 690px) {
    .new-artwork__group {
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .new-artwork__group-item {
        min-width: 200px;
        margin-bottom: 20px;
    }

    .new-artwork__group-item:nth-child(2n) {
        margin-right: 0px;
    }
}

@media (max-width: 555px) {
    .new-artwork__heading {
        font-size: 17px;
    }

    .new-artwork__tag-container {
        display: block;
        margin-bottom: 53px;
    }

    .new-artwork__tag-color-picker {
        margin-left: 0px;
    }

    .new-artwork__tag-color-picker {
        top: 14px;
    }
}

@media (max-width: 518px) {
    .new-artwork__group-item {
        min-width: 170px;
        max-width: 170px;
    }
}

@media (max-width: 420px) {
    .new-artwork__group-item {
        min-width: 160px;
        max-width: 160px;
    }
}

@media (max-width: 377px) {
    .new-artwork__group-item {
        min-width: 150px;
        max-width: 150px;
    }
}



.notifications__container {
    width: 100%;
    min-height: 100vh;
    background-color: white;
}

.notifications__box {
    width: 100%;
    display: flex;
}

.notifications__notifications {
    width: 100%;
    padding-top: 30px;
}

.notifications__notifications-wrap {
    width: 90%;
    margin: 0 auto;
    max-width: 1000px;
}

.notifications__search-container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    background: rgb(245,245,245);
    border-radius: 5px;
    align-items: center;
    opacity: 0.6;
    transition: opacity 0.1s;
}

.notifications__search-container.active {
    opacity: 1;
}

.notifications__search-icon {
    min-width: 28px;
    height: auto;
    fill: rgb(165, 163, 163);
    margin-left: 10px;
    margin-right: 15px;
}


.notifications__search-input {
    width: 100%;
    background: transparent;
    outline: none;
    border: none;
    padding: 15px 10px 15px 0px;
    font-family: 'Montserrat';
    font-size: 18px;
    color:rgb(165, 163, 163);
    font-weight: 500;
}

.profile-mobile-menu__button {
    position: absolute;
    z-index: 4;
    color: black;
    background: white;
    border-radius: 50%;
    font-size: 25px;
    width: 41px;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 20px;
    left: 23px;
    cursor: pointer;
}

.profile-mobile-menu__button.fixed {
    position: fixed;
}

.close-profile-mobile-menu {
    position: absolute;
    top: 4px;
    left: 4px;
    opacity: 1;
    width: 2em;
    height: 2em;
}

.mobilemenu__width.border {
    border: none !important;
}
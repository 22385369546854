.contact__container {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 50px;
}

.contact__wrap {
    width: 90%;
}

.contact__container-inner {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.contact__form {
    width: 50%;
    background: #be00bf;
    border-radius: 20px;
    padding: 40px 20px;
}

.contact__meeting {
    width: 44%;
}

.contact__form-button {
    margin: 0 auto;
    display: block;
    padding: 3px 40px;
    border: none;
    border-radius: 20px;
    background: white;
    color: #be00bf;
    font-size: 20px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    width: fit-content;
}

.contact__form-button.loading {
    opacity: 0.6;
    cursor: default;
    display: flex;
    align-items: center;
}

.contact__form-button.loading span {
    margin-left: 10px;
}

.contact__form-button.loading .spinner-border {
    width: 1.6rem;
    height: 1.6rem;
}

.contact__form-heading {
    text-align: center;
    font-size: 25px;
    font-weight: 500;
    color: white;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 20px;
}

.contact__form-text {
    font-size: 20px;
    font-weight: 100;
    color: white;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 0px;
}

.contact__form-text.bottom {
    margin-bottom: 20px;
}

.contact__form-input {
    width: 100%;
    border: none;
    font-size: 19px;
    font-weight: 500;
    color: black;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 20px;
    border-radius: 20px;
    outline: none;
    padding: 10px 20px;
}

.contact__form-input::placeholder {
    color: #be00bf;
}

textarea.contact__form-input {
    height: 380px;
    resize: none;
}

.contact__meeting-image {
    width: 80%;
    margin: 0 auto;
    display: block;
    margin-bottom: 20px;
}


.contact__meeting-text {
    margin-bottom: 5px;
    margin-top: -10px;
    font-size: 23px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    color: black;
    text-align: center;
}

.contact__meeting-link {
    font-size: 23px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    color: #be00bf;
    margin: 0 auto;
    display: block;
    width: fit-content;
    margin-bottom: 26px;
}

.contact__meeting-text.smaller {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
}

.contact__social-media {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact__social-media-icon {
    min-width: 50px;
    width: 50px;
    margin: 0 8px;
    cursor: pointer;
}


.contact__social-media-icon svg {
    width: 75%;
    fill: #be00bf;
}

.contact__meeting-group {
    width: 100%;
}

.contact__container .error-div__icon, .contact__container .error-div__text {
    color: #000000;
}

@media (max-width: 1645px) {
    .contact__meeting-text, .contact__meeting-link {
        font-size: 20px;
    }

    .contact__meeting-text.smaller {
        font-size: 18px;
    }

    .contact__social-media-icon {
        min-width: 44px;
        width: 44px;
    }

    .contact__form-heading {
        font-size: 22px;
    }

    .contact__form-text {
        font-size: 18px;
    }

    .contact__form-input {
        font-size: 17px;
    }

    .contact__form-button {
        font-size: 18px;
    }
}

@media (max-width: 1236px) {
    .contact__social-media-icon {
        min-width: 40px;
        width: 40px;
        margin: 0 4px;
    }

    .contact__meeting-text, .contact__meeting-link {
        font-size: 18px;
    }

    .contact__meeting-text.smaller {
        font-size: 16px;
    } 

    .contact__form-heading {
        font-size: 20px;
    }

    .contact__form-text {
        font-size: 17px;
    }

    .contact__form-input {
        font-size: 16px;
    }
}

@media (max-width: 1034px) {
    .contact__container-inner {
        flex-wrap: wrap;
        justify-content: center;
    }

    .contact__form {
        width: 75%;
    }

    .contact__meeting {
        width: 90%;
    }

    .contact__meeting-image {
        width: 55%;
    }
}

@media (max-width: 786px) {
    .contact__container {
        padding-top: 70px;
    }
}

@media (max-width: 640px) {
    .contact__form {
        width: 88%;
    }
}

@media (max-width: 530px) {
    .contact__meeting {
        width: 100%;
    }

    .contact__meeting-image {
        width: 48%;
    }

    .contact__meeting-text, .contact__meeting-link {
        font-size: 16px;
    }

    .contact__meeting-text.smaller {
        font-size: 15px;
    } 
}


@media (max-width: 450px) {
    .contact__form {
        width: 100%;
    }

    .contact__social-media-icon {
        min-width: 35px;
        width: 35px;
        margin: 0 4px;
    }
}

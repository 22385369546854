//Width should be the same as margin left of .mobile-menu-open!

.mobile-menu-aside {
    background-color: black;
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

.mobile-menu__logo {
    width: 70%;
    margin: 0 auto;
    margin-top: 120px;
    margin-bottom: 50px;
}

.mobile-menu__option {
    background: var(--gradient-login);
    width: 93%;
    margin-left: auto;
    width: fit-content;
}

.mobile-menu__gallery-pic {
    width: 150%;
}

.menu-option__current .mobile-menu__suarteworld-icon {
    fill: white;
}

.mobile-menu__suarteworld-icon {
    stroke-width: 12px;
    overflow: inherit;
    fill:none;
    stroke:#fff;
    stroke-miterlimit:10;
}

.mobile-menu__heading {
    width: 100%;
}

.mobile-menu__search, .mobile-menu__heart, .mobile-menu__cart, .mobile-menu__user, .mobile-menu__gallery, .mobile-menu__suarteworld {
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding: 17px;
    cursor: pointer;
    // padding-left: 12%;
    background: black;
    padding-right: 30px;
    justify-content: end;
    padding-left: 30px !important;
}


.active-option-top .bg-active{
    border-bottom-right-radius: 35px;
}

.active-option-bottom .bg-active{
    border-top-right-radius: 35px;
}

.mobile-menu__gallery {
    padding: 10px;
    padding-right: 30px;
}

.mobile-menu__suarteworld {
    padding: 8px;
    padding-right: 30px;
}

.mobile-menu-search__icon, .mobile-menu-heart__icon, .mobile-menu-gallery__icon, .mobile-menu-cart__icon, .mobile-menu-user__icon, .mobile-menu-suarteworld__icon {
    min-width: 27px;
    max-width: 27px;
    transition: all 300ms;
    fill: var(--gallery-view-navbar-text);
    position: relative;
}

.mobile-menu-suarteworld__icon {
    min-width: 30px;
}

.mobile-menu-gallery__icon {
    margin-right: 8px;
}

.mobile__notification {
    position: absolute;
    top: 0px;
    right: -4px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ffbe2e;
    opacity: 0;
}

.menu-option__current {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
}

.menu-option__current .bg-active {
    background: none;
}

.notification-active {
    opacity: 1;
}


.mobile-menu-cart__icon {
    width: 29px;
}

.mobile-menu-user__icon {
    min-width: 21px;
    left: -3px;
    top: -2px;
}

.mobile-menu-search__text, .mobile-menu-heart__text, .mobile-menu-cart__text, .mobile-menu-user__text, .mobile-menu-gallery__text, .mobile-menu-suarteworld__text {
    color: var(--gallery-view-navbar-text);
    margin-right: 15px;
    font-size: 17px;
    font-family: 'Montserrat', sans-serif;
}

.mobile-menu__mode {
    width: 60px;
    margin: 0 auto;
    padding: 10px;
    margin-top: 40px;
    border-radius: 50px;
    background: #444444;
    box-shadow:  1px 3px 1px #3a3a3a, -1px -2px 3px #4e4e4e;
    cursor: pointer;
}

.mobile-menu-sun, .mobile-menu-moon {
    width: 100%;
}

.mobile-menu-aside a {
    text-decoration: none;
}

.mobilemenu__width {
    width: 300px;
    border-left: none;
}
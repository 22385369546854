.reset-container {
    width: 41%;
    min-height: 500px;
    position: relative;
    padding-top: 30px;
}

.reset-container__question, .reset-container__second-line, .reset-container__third-line {
    width: 80%; 
    display: block;
    margin: 0 auto;
    color: white;
    text-align: center;
    font-weight: bold;  
    font-size: 18px;
}

.reset-container__question {
    margin-top: 25px;
}

.reset-container__second-line {
    margin-top: 20px;
}

.email-input--reset {
    margin-top: 40px;
}

.reset-container__success-message {
    color: white;
    display: block;
    text-align: center;
    margin-top: 15px;
    font-weight: bold;  
    display: none;
}

.reset-container__back-icon {
    color: rgb(161, 160, 160);
    font-size: 36px;
    cursor: pointer;
    position: absolute;
    top: -100px;
    left: -140px;
}

.reset-container__back-icon:hover {
    text-shadow: 0px 0px 2px rgb(236, 236, 168);
}

.resend-container {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    color: white;
    font-size: 18px;
}

.resend-container__request {
    color: green;
}

@media (max-width: 1300px) {
    .reset-container {
        width: 50%;
        padding-left: 0px;
        padding-right: 0px;
    }

    .reset-container__back-icon {
        top: -75px;
        left: -70px;
    }


}

@media (max-width: 850px) {

    .reset-container {
        width: 70%;
    }

    .reset-container__back-icon {
        left: -60px;
    }
}

@media (max-width: 600px) {
    .reset-container {
        width: 87%;
    }

    .reset-container__back-icon {
        top: -70px;
        left: -10px;
    }

    .reset-container__question, .reset-container__second-line, .reset-container__third-line {
        font-size: 16px;
    }
}



.profile-template {
    width: 100%;
    min-height: 100vh;
    background: #242424;
    position: relative;
}

.profile__container {
    width: 100%;
    display: flex;
    position: relative;
    background: #fff;
}

.profile__navigation {
    width: 15%;
    height: fit-content;
    position: sticky;
    top: 0;
    z-index: 2;
}


.profile__navigation.box-shadow {
    box-shadow: 0px 0px 16px 3px #ccc;
}


.profile__navigation-mobile {
    display: none;
}

.profile__content {
    width: 100%;
    position: relative;
}

@media (max-width: 1466px) {
    .profile__navigation {
        width: 17%;
    }

    .profile__content {
        width: 83%;
    }
}

@media (max-width: 1028px) {
    .profile__navigation {
        width: 22%;
    }

    .profile__content {
        width: 78%;
    }
}

@media (max-width: 786px) {
    .profile__navigation {
        display: none;
    }

    .profile__content {
        width: 100%;
    }

    .profile__navigation-mobile {
        display: block;
    }
}
.artwork-card-template {
    width: 100%;
    font-family: 'Monstserrat', sans-serif;
    margin-bottom: 30px;
    box-shadow: var(--neumorphism-gallery-view);
    padding: 15px;
}

.artwork-card__image-template {
    width: 100%;
    aspect-ratio: 5/4;
    background-color: #414141;
    margin-bottom: 20px;
}

.artwork-card__details-template-wrap {
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
}

.artwork-card__name-template {
    width: 70%;
    padding: 10px;
    background-color: #414141;
    border-radius: 5px;
}

.artwork-card__price-author-template-container {
    width: 100%;
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
    border-radius: 5px;
}


.artwork-card-price-author-template__item {
    width: 34%;
    padding: 10px;
    background-color: #414141;
    border-radius: 5px;
}


.new-artwork__add-images-slider {
    width: 100%;
    margin-bottom: 20px;
}

.new-artwork__add-images-slider {
    width: 100%;
}

.new-artwork__add-new-button {
    width: 100%;
    aspect-ratio: 1/1;
    box-shadow: 0 0 6px #ccc;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-size: 30px;
}

.new-artwork__image-container {
    width: 100%;
    aspect-ratio: 1/1;
    box-shadow: 0 0 6px #ccc;
}

.new-artwork__image-loading {
    width: 100%;
    aspect-ratio: 1/1;
    background: #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
}

.new-artwork__image {
    width: 100%;
    position: relative;
    aspect-ratio: 1/1;
}

.new-artwork__image-fail {
    width: 100%;
    aspect-ratio: 1/1;
    border: 2px dashed red;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.new-artwork__image-fail i {
    color: red;
    font-size: 36px;
}

.new-artwork__image-fail button {
    margin-top: 20px;
    padding: 6px;
    font-family: 'Montserrat', 'sans-serif';
    font-weight: 600;
    border-radius: 10px;
    font-size: 17px;
}

.new-artwork__image-fail span {
    color: red;
    font-family: 'Montserrat', 'sans-serif';
    font-weight: 600;
    font-size: 17px;
    margin-top: 5px;
}

.new-artwork__image img {
    width: 100%;
    aspect-ratio: inherit;
    object-fit: cover;
    cursor: pointer;
}

.new-artwork__add-images-slider .swiper-wrapper {
    padding: 4px;
}

.new-artwork__image-buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 5px;
}

.new-artwork__image-button {
    width: 40px;
    aspect-ratio: 1/1;
    background: rgb(255,133,28);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 10px;
    cursor: pointer;
}

.new-artwork__image-button i{
    color: white;
    font-size: 26px;
}

.new-artwork__image-button.--remove i {
    font-size: 30px;
}

.new-artwork__image-button.disabled {
    opacity: 0.5;
    pointer-events: none;
}


@media (max-width: 1025px) {
    .new-artwork__image-button {
        width: 33px;
        margin: 0px 6px;
    }

    .new-artwork__image-button i {
        font-size: 22px;
    }

    .new-artwork__image-button.--remove i {
        font-size: 25px;
    }

    .new-artwork__image-fail span {
        font-size: 15px;
    }

    .new-artwork__image-fail button {
        padding: 4px;
        font-size: 15px;
    }

    .new-artwork__image-fail i {
        font-size: 31px;
    }
}
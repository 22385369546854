.profile-navigation-container {
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    background-color: #fff;
    padding-bottom: 20px;
    scrollbar-width: thin;
    scrollbar-color: #353535 #FFF;
}

//WEBKIT SCROLLBAR STYLES
.profile-navigation-container::-webkit-scrollbar {
    width: 0.2em;
}

.profile-navigation-container::-webkit-scrollbar-track {
    background: #FFF;
}

.profile-navigation-container::-webkit-scrollbar-thumb {
    background: #353535;
    border-radius: 20px;
}

.profile-navigation__exit {
    text-decoration: none;
    margin: 0 auto;
    cursor: pointer;
    display: block;
    width: 34px;
    margin-top: 20px;
}

.profile-navigation__exit svg {
    width: 100%;
    overflow: inherit;
}

.profile-navigation__edit {
    text-decoration: none;
    margin: 0 auto;
    display: flex; 
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 2px;
    background: #222222;
    border-radius: 20px;
    width: fit-content;
    margin-top: 20px;
    overflow: hidden;
}

.profile-navigation__edit-inner {
    width: 100%;
    background: #FFF;
    border-radius: 20px;
    padding: 3px;
    padding-left: 12px;
    padding-right: 12px;
}

.profile-navigation__edit.active {
    background: var(--gradient-login);
}

.active .profile-navigation__edit-inner {
    mask-image: linear-gradient(90deg, rgba(250,185,50,0.9) 0%, rgba(224,99,113,0.9) 64%, rgba(181,66,139,0.9) 100%);
}

.profile-navigation__edit-h1 {
    font-family: 'Montserrat', sans-serif;
    font-size: 19px;
    font-weight: 600;
    color: black;
    margin: 0;
}

.active .profile-navigation__edit-h1 {
    background: var(--gradient-login);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
}

.profile-navigation__edit:hover {
    color: black;
}

.profile-navigation__gallery-name {
    font-size: 20px;
    color: black;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    text-align: center;
    margin-bottom: 0px;
    width: 100%;
    padding-left: 3px;
    padding-right: 3px;
    margin-top: 10px;
}

.profile__logo {
    width: 85%;
    margin: 0 auto;
    display: block;
    aspect-ratio: 1/1;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin-top: 7px;
}


@media (max-width: 1726px) {
    .profile-navigation__gallery-name {
        font-size: 18px;
        margin-top: 18px;
    }
    .profile-navigation__exit {
        width: 28px;
    }

    .profile__logo {
        width: 70%;
    }

    .profile-navigation__edit-h1 {
        font-size: 17px;
    }
}

@media (max-width: 1466px) {
    .profile-navigation__gallery-name {
        font-size: 16px;
    }

    .profile-navigation__edit-h1 {
        font-size: 16px;
    }
}


@media (max-width: 1180px) {
    .profile-navigation__gallery-name {
        font-size: 15px;
    }

    .profile-navigation__exit {
        width: 26px;
    }

    .profile-navigation__edit-h1 {
        font-size: 15px;
    }
}

@media (max-width: 786px) {
    .profile-navigation__gallery-name {
        font-size: 16px;
        margin-top: 18px;
    }

    .profile__logo {
        width: 44%;
    }
}






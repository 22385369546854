.notifications__list{
    width: 100%;
    padding-top: 30px;
    padding-bottom: 70px;
}

.notifications__item {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    cursor: pointer;
    transition: background-color 0.2s;
    padding-top: 15px;
    padding-bottom: 15px;
    position: relative;
    z-index: 2;
}

.notifications__item:hover {
    background-color: rgba(214, 76, 131,0.1);
}

.notifications__item.active {
    border-left: 4px solid rgb(214, 76, 131);
    background-color: rgba(214, 76, 131,0.1);
}

.notifications__item-wrap {
    width: 90%;
    max-width: 1000px;
    margin: 0 auto;
}

.notifications__item-content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.notifications__item-image {
    min-width: 70px;
    max-width: 70px;
    aspect-ratio: 1/1;
    background: #ccc;
    border-radius: 50%;
    overflow: hidden;
}

.notifications__item-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.notifications__item-text-container {
    width: 91%;
}

.notifications__item-text-upper, .notifications__item-text-lower {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.notifications__item-text-upper {
    margin-bottom: 10px;
    position: relative;
}

.notifications__item-heading {
    width: 70%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 20px;
    color: black;
    font-weight: 600;
    font-family: 'Montserrat';
    margin-bottom: 0px;
}

.notifications__item-date {
    font-size: 18px;
    color: black;
    font-weight: 600;
    font-family: 'Montserrat';
}

.notifications__item-text-preview {
    width: 75%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 17px;
    color: gray;
    font-weight: 100;
    font-family: 'Montserrat';
    margin-bottom: 0px;
}

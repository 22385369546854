[data-theme="dark"] {
    --background: #242424;
}

[data-theme="light"] {
    --background: #EAEBF3;
}

.page-template-container {
    width: 100%;
    min-height: 100vh;
    background-color: var(--background);
    transition: margin 300ms, background-color 600ms;
    position: relative;
}












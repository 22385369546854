[data-theme="light"] {
    --audio-neumorphism: 2px 2px 1px #818186, -1px -1px 3px #fff;
    --timer-color: black;
}

[data-theme="dark"] {
    --audio-neumorphism: 2px 2px 1px #141414, -1px -1px 3px #343434;
    --timer-color: #BEBBBB;
}


.audio-container {
    width: 100%;
    transition: background-color 600ms ease;
}

.audio-container .rhap_container {
    background: var(--background);
    box-shadow: var(--audio-neumorphism);
    border-radius: 25px;
    padding: 3px;
    padding-left: 6px;
    padding-right: 17px;
    transition: var(--change-mode-transition);
}

.audio-container .rhap_additional-controls {
    display: none;
}

.audio-container .rhap_main-controls-button {
    color: var(--gallery-view-printing-name);
    transition: var(--change-mode-transition);
}

.audio-container .rhap_progress-section {
    flex: 8 1 auto;
}

.audio-container .rhap_time {
    display: none;
    // color: var(--timer-color);
    // font-family: 'Montserrat', sans-serif;
    // transition: var(--change-mode-transition);
    // margin-right: 3px;
    // margin-top: 2px;
}

.audio-container .rhap_controls-section {
    flex: none;
}

.audio-container .rhap_progress-bar {
    background: transparent;
}

.audio-container .rhap_progress-indicator {
    width: 15px;
    height: 15px;
    top: -2px;
    background: var(--gallery-view-printing-name);
    transition: background 600ms ease;
}

.audio-container .rhap_download-progress {
    background: transparent;
}

.audio-container .rhap_progress-bar {
    height: 8px;
    box-shadow: var(--painting-view-neumorphism);
    transition: var(--change-mode-transition);
    border-radius: 13px;
}

.audio-container .rhap_progress-filled, .audio-container .rhap_download-progress {
    height: 7px;
    top: 1px;
}

.audio-container .rhap_progress-filled {
    background: rgba(0, 0, 0, 0) linear-gradient(90deg, rgb(255, 190, 46) 0%, rgb(190, 0, 191) 100%) repeat scroll 0% 0%;
}

.add-accordeon {
    width: 100%;
    position: relative;
}

.add-accordeon__icon {
    width: 45px;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-size: 27px;
    border-radius: 50%;
    border: 2px solid rgb(255,133,28);
    transition: background 0.2s ease-in-out, transform 0.2s ease-in-out;
    cursor: pointer;
    color: white;
    z-index: 10;
    background: #242424;
}

.add-accordeon__control-overflow {
    width: 20px;
    position: absolute;
    top: 0;
    background: #242424;
    height: 20px;
    z-index: 9;
}

.add-accordeon__options {
    width: 0%;
    max-height: 0px;
    overflow: hidden; 
    position: absolute;
    z-index: 8;
    top: 0;
    border: 2px solid rgb(255,133,28);
    border-radius: 20px;
    transition: width 0.25s ease-in-out, max-height 0.25s ease-in-out, padding 0.25s ease-in-out;
    transition-delay: 0.1s;
}

.add-accordeon.active .add-accordeon__options {
    width: 270px;
    max-height: 200px;
}

.add-accordeon.active .add-accordeon__icon {
    transform: rotate(180deg);
    background: rgb(255,133,28);
}


.add-accordeon__options-wrap {
    width: 100%;
    padding: 10px;
    padding-left: 55px;
    background: #242424;
}

.add-accordeon__options_option {
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    color: white;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    margin: 0px;
    margin-bottom: 10px;
}

.add-accordeon__options_option.heading{
    color: rgb(255,133,28);
    cursor: default;
}


$color: white;

.fetch-loader__wrap {
    width: 100%;
    min-height: 100vh;
    background: rgba(36,36,36,0.8);
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    z-index: 3;
    height: 100%;
}

.fetch-loader {
    width: 60px;
    height: 50px;
    text-align: center;
    font-size: 10px;
    position: sticky;
    top: 30%;
}

.fetch-loader > div {
    height: 100%;
    width: 6px;
    display: inline-block;
    float: left;
    margin-left: 2px;
    -webkit-animation: delay 0.8s infinite ease-in-out;
    animation: delay 0.8s infinite ease-in-out;
}

.fetch-loader .loading-bar {
    background-color: $color;
}

.fetch-loader .bar2 {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;   
}

.fetch-loader .bar3 {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;  
}

.fetch-loader .bar4 {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
}

.fetch-loader .bar5 {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
}

.fetch-loader .bar6 {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;  
}
  
  
@-webkit-keyframes delay {
    0%, 40%, 100% { -webkit-transform: scaleY(0.05) }  
    20% { -webkit-transform: scaleY(1.0) }
}
  
@keyframes delay {
    0%, 40%, 100% { 
        transform: scaleY(0.05);
        -webkit-transform: scaleY(0.05);
    }  20% { 
        transform: scaleY(1.0);
        -webkit-transform: scaleY(1.0);
    }
}
.login-slide {
    width: 200%;
    display: flex;
    transition: all 500ms ease;
    height:100%;
}

.login-slider {
    width: 100%;
    overflow: hidden;
}

.login-slider__item {
    min-width: 50%;
    height:100%;
}

.slide-right {
    transform: translateX(-50%);
}


@media (max-width: 1300px) {
    .login-slide {
        transition: all 400ms ease;
    }
}

@media (max-width: 600px) {
    .login-slide {
        transition: all 250ms ease;
    }
}



[data-theme = "dark"] {
    --rope-color: #242424;
    --rope-fill: white;
}

.tag-label {
    position: relative;
    margin-left: -21px;
}

.tag {
    width: 88%;
    stroke-width: 8px;
    transition: all 200ms;
}

.tag-container {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.tag__dimensions {
    font-family: 'Montserrat', sans-serif;
    font-size: 17px;
    color: var(--gallery-view-navbar-text);
    transition: var(--change-mode-transition);
}

.tag__price {
    position: absolute;
    top: 0%;
    left: 0%;
    font-family: 'Montserrat', sans-serif;
    font-size: 23px;
    color: var(--gallery-view-navbar-text);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
    font-weight: 500;
    overflow:hidden;
}

.cuerda {
    width: 100px;
    min-width: 100px;
    position: relative;
    z-index: 1;
    fill: #eaebf3; 
    stroke: #242424; 
    stroke-miterlimit:10; 
    stroke-width:2px;
    transition: var(--change-mode-transition)
}

.tag-circle {
    fill: var(--background);
}

.label-selected {
    stroke: var(--background);
   // fill: var(--background);
}

.tag__price-box {
    position: absolute;
	z-index: 3;
	top: -20%;
	left: 52%;
	font-size: 0.8em;
	transform: translate(-50%,-50%);
}

.tag__price-cart {
	position: absolute;
	z-index: 2;
	top: 50%;
    font-size: 1.2em;
	left: -16%;
	transform: translate(-52%,-52%);
}

.tag__price span {
	position: absolute;
	z-index: 1;
	left: 50%;
	top: 50%;
	transform: translate(-50%,-50%);
}

.tag__price-price {
	opacity: 1;
}

.tag__price-added {
	opacity: 0;
    font-size: 19px;
    margin-left: 5px;
}


.tag__price-price, .tag__price-added {
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}


.tag__price.already-in-cart .tag__price-price {
    opacity: 0;
}


.tag__price.already-in-cart .tag__price-added {
    opacity: 1;
}


.tag__price.removed .tag__price-cart {
	animation: cart-reverse 1s ease-in-out;
}

.tag__price.removed .tag__price-box{
	animation: box-reverse 1s ease-in-out forwards;
}

.tag__price.removed .tag__price-price {
	animation: txt1-reverse 1s ease-in-out forwards;
}

.tag__price.removed .tag__price-added {
	animation: txt2-reverse 1s ease-in-out forwards;
}

.tag__price.clicked .tag__price-cart {
	animation: cart 1s ease-in-out forwards;
}

.tag__price.clicked .tag__price-box{
	animation: box 1s ease-in-out forwards;
}

.tag__price.clicked .tag__price-price {
	animation: txt1 1s ease-in-out forwards;
}

.tag__price.clicked .tag__price-added {
	animation: txt2 1s ease-in-out forwards;
}

.tag__price.disabled {
    cursor: default;
}

.tag__price.already-in-cart {
    cursor: pointer;
}

.tag__price.disabled .tag__price-price {
    width: 100%;
    text-align: center;
    font-size: 19px;
    left: 56%;
}

@keyframes cart {
	0% {
		left: -16%;
	}
	40%, 60% {
		left: 50%;
	}
	100% {
		left: 150%;
	}
}

@keyframes cart-reverse {
	0% {
		left: 150%;
	}
	40%, 60% {
		left: 50%;
	}
	100% {
		left: -16%;
	}
}



@keyframes box {
	0%, 40% {
		top: -20%;
	}
	60% {
		top: 40%;
		left: 52%;
	}
	100% {
		top: 40%;
		left: 142%;
	}
}

@keyframes box-reverse {
	0% {
		top:40%;
        left: 152%;
	}
    40% {
        top:40%;
        left: 50%
    }

    60%, 100% {
        top: -20%;
    }
}

@keyframes txt1 {
	0% {
		opacity: 1;
	}
	20%, 100% {
		opacity: 0;
	}
}

@keyframes txt1-reverse {
	0%, 80% {
		opacity: 0;
	}
    100% {
		opacity: 1;
	}
}



@keyframes txt2 {
	0%, 80% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes txt2-reverse {
	0% {
        opacity: 1;
    }

    20%, 100% {
        opacity: 0;
    }
}





@media (max-width: 1412px) {
    .tag-label {
        margin-left: -22px;
    }

    .tag__price {
        font-size: 22px;
    }

    .tag__dimensions {
        font-size: 16px;
    }
}

@media (max-width: 1200px) {
    .tag-label {
        margin-left: -19px;
    }

    .tag-label.tag-million {
        margin-left: -17px;
    }

    .cuerda {
        width: 90px;
        min-width: 90px;
    }
}

@media (max-width: 1100px) {
    .tag-million .tag__price {
        font-size: 19px;
    }

    .tag__price.disabled .tag__price-price {
        font-size: 17px;
    }
}

@media (max-width: 1000px) {
    .tag-label {
        margin-left: -17px;
    }

    .tag__price {
        font-size: 20px;
    }

    .tag__dimensions {
        font-size: 15px;
    }
}

@media (max-width: 991px){
    .tag__price-added {
        font-size: 17px;
    }

}

@media (max-width: 976px) {
    .tag-label.tag-million {
        margin-left: -16px;
    }

    .cuerda {
        width: 77px;
        min-width: 77px;
    }

    .tag__price.disabled .tag__price-price {
        font-size: 16px;
    }
}



@media (max-width: 883px) {
    .tag-million .tag__price {
        font-size: 17px;
    }
}


@media (max-width: 868px) {
    .tag-million .tag__price {
        font-size: 20px;
    }

    .tag__price.disabled .tag__price-price {
        font-size: 15px;
    }
}

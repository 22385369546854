.showroom__tutorial-popup {
    width: 80%;
    max-width: 900px;
}

.showroom__tutorial-popup .modal-content {
    border: 3px solid rgb(255 133 28);
    box-shadow:rgb(0 0 0 / 35%) 0px 29px 88px;
}

.showroom__tutorial-heading {
    font-size: 20px;
    font-weight: 600;
    color: rgb(255 133 28);
    margin-bottom: 35px;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    padding-top: 25px;
}

.showroom__tutorial-wrap {
    width: 88%;
    margin: 0 auto;
} 

.showroom__tutorial-text {
    font-size: 20px;
    font-weight: 600;
    color: black;
    margin: 0px;
    font-family: 'Montserrat', sans-serif;
}

.showroom__tutorial-text.minor {
    font-size: 15px;
    margin-bottom: 30px;
    text-align: justify;
}

.showroom__tutorial-text .showroom__tutorial-orange {
    color: rgb(255 133 28);
}

.showroom__tutorial-x-container {
    width: 25px;
    aspect-ratio: 1/1;
    color: white;
    background: #242424;
    border-radius: 5px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
}

.showroom__tutorial-inner-wrap {
    width: 90%;
    margin: 0 auto;
    margin-top: 20px;
}

.showroom__tutorial-actions-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 1px solid rgb(255 133 28);
    margin-bottom: 20px;
}

.showroom__tutorial-action {
    width: 42%;
}

.showroom__tutorial-action-text {
    font-size: 20px;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    color: black;
}


.showroom__tutorial-action-circle {
    position: relative;
    width: 30px;
    aspect-ratio: 1/1;
    border-radius: 50%;
    border: 3px solid rgb(255 133 28);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    top: 7px;
    margin-right: 6px;
}

.showroom__tutorial-action-inner-circle {
    position: absolute;
    font-weight: 600;
}

.showroom__tutorial-action-items {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
}

.showroom__tutorial-action-item {
    width: 75px;
    aspect-ratio: 8/7;
    background-color: black;
    height: fit-content;
    position: relative;
    display: flex;
    justify-content: center;
    color: white;
    align-items: center;
    font-size: 35px;
}

.showroom__tutorial-action-item.selected {
    aspect-ratio: 2/3;
    border: 4px solid rgb(255 133 28);
}

.showroom__tutorial-action-item.selected.opacity {
    background: rgba(255,133,28,0.3);
}

.showroom__tutorial-item-options {
    width: 60%;
    height: 6px;
    border-radius: 4px;
    background-color:rgb(255 133 28);
    display: block;
    position: absolute;
    bottom: 5px;
}

.showroom__tutorial-point {
    animation-name: showroom__tutorial-hand-touch;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}

.showroom__tutorial-hand {
    position: absolute;
    overflow: inherit;
    bottom: -26px;
    text-shadow: -2px 0 #000, 0 2px #000, 2px 0 #000, 0 -2px #000;
    animation-name: showroom__tutorial-hand-animation;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}

@keyframes showroom__tutorial-hand-touch {
    0% {
        transform: translate(0px, 0px);
    }
    50% {
        transform: translate(0px, 10px);
    }
    100% {
        transform: translate(0px, 0px);
    }
}

@keyframes showroom__tutorial-hand-animation {
    0% {
        transform: translateX(-10px);
    }
    50% {
        transform: translateX(20px);
    }
    100% {
        transform: translateX(-10px);
    }
}

.showroom__tutorial-actions-explain-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.showroom__tutorial-actions-explain {
    margin: 0px 10px;
}

.showroom__tutorial-actions-explain:first-child {
    margin-left: 0px;
}

.showroom__tutorial-actions-explain:last-child {
    margin-right: 0px;
}

.showroom__tutorial-action-explain-button {
    display: block;
    margin: 0 auto;
    width: fit-content;
    border: 2px solid rgb(255 133 28);
    background: rgba(255,133,28,0.6);
    color: black;
    font-family: 'Montserrat';
    font-size: 19px;
    border-radius: 20px;
    font-weight: 600;
    padding: 0px 25px;
    margin-bottom: 10px;
}

.showroom__tutorial-actions-explain .showroom__tutorial-action-text {
    text-align: center;
}

@media (max-width: 931px) {
    .showroom__tutorial-text {
        font-size: 18px;
    }

    .showroom__tutorial-x-container {
        width: 22px;
        margin-left: 7px;
    }

    .showroom__tutorial-action-circle {
        width: 25px;
    }

    .showroom__tutorial-action-text {
        font-size: 17px;
    }

    .showroom__tutorial-action {
        width: 45%;
    }

    .showroom__tutorial-action-item{
        width: 70px;
        font-size: 30px;
    }

    .showroom__tutorial-hand {
        bottom: -22px;
    }

    .showroom__tutorial-heading {
        font-size: 19px;
    }

    .showroom__tutorial-action-explain-button {
        font-size: 17px;
        padding: 0px 20px;
    }
}

@media (max-width: 786px) {
    .showroom__tutorial-action {
        width: 260px;
        margin: 0 auto;
    }

    .showroom__tutorial-action:last-child {
        margin-top: 20px;
    }

    .showroom__tutorial-actions-container {
        flex-wrap: wrap;
    }
}

@media (max-width: 600px) {
    .showroom__tutorial-text {
        font-size: 17px;
    }

    .showroom__tutorial-x-container {
        width: 20px;
        margin-left: 7px;
    }

    .showroom__tutorial-action-circle {
        width: 22px;
        border: 2px solid rgb(255 133 28);
    }

    .showroom__tutorial-action-text {
        font-size: 16px;
    }

    .showroom__tutorial-heading {
        font-size: 17px;
        padding-top: 40px;
        margin-bottom: 23px;
    }

    .showroom__tutorial-text.minor {
        font-size: 14px;
    }
}

@media (max-width: 435px) {
    .showroom__tutorial-inner-wrap {
        width: 100%;
    }



}



.invoices__container {
    width: 100%;
    min-height: 100vh;
    background-color: white;
}

.invoices__wrap {
    width: 90%;
    margin: 0 auto;
    padding-top: 80px;
    padding-bottom: 70px;
}

.invoices__title {
    width: 100%;
    font-size: 30px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    color: black;
    padding-left: 20px;
}

.invoices__list {
    width: 100%;
    background: #efefef;
    border-radius: 10px;
    padding: 12px;
    margin-top: 30px;
}

.invoices__item {
    width: 100%;
    background: white;
    border-radius: 10px;
    padding: 22px 19px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.invoices__item:last-child {
    margin-bottom: 0;
}

.invoices__item-text, .invoices__item-download {
    font-size: 20px;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    color: black;
    margin: 0px;
}

.invoices__item-download {
    margin-left: 20px;
    font-weight: 500;
    color: #e62b91;
    display: flex;
    align-items: center;
}

.invoices__item-download svg {
    fill: #e62b91;
    transform: scale(0.75);
    margin-left: -5px;
}

.invoices__item-download:hover {
    color: rgb(0, 102, 255);
    cursor: pointer;
}

.invoices__item-download:hover svg {
    fill: rgb(0, 102, 255);
}

@media (max-width: 1450px) {
    .invoices__title {
        font-size: 27px;
    }

    .invoices__item-text, .invoices__item-download {
        font-size: 18px;
    }

    .invoices__item-download svg {
        transform: scale(0.7);
    }
}

@media (max-width: 1034px) {
    .invoices__title {
        font-size: 25px;
    }

    .invoices__item-text, .invoices__item-download {
        font-size: 17px;
    }
}

@media (max-width: 870px) {
    .invoices__title {
        font-size: 23px;
    }

    .invoices__item-text, .invoices__item-download {
        font-size: 16px;
    }

    .invoices__item-download svg {
        transform: scale(0.67);
    }
}

@media (max-width: 810px) {
    .invoices__item-text span {
        display: none;
    }

    .invoices__item {
        padding: 11px 12px;
    }
}

@media (max-width: 545px) {
    .invoices__title {
        font-size: 21px;
    }

    .invoices__item-download  {
       margin-right: -16px;
    }
}

@media (max-width: 393px) {
    .invoices__item-download svg {
        display: none;
    }

    .invoices__item-download  {
        margin-right: 0px;
    }  
}
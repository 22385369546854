.edit-profile__animated-container {
    width: 100%;
    min-height: 100vh;
}

.edit__center {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 25px;
    padding-bottom: 25px;
}


.edit__box-info {
    width: 93%;
    margin: 0 auto;
    min-height: 90vh;
    border-radius: 40px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    position: relative;
    padding-bottom: 30px;
}

.edit__changable-container {
    width: 100%;
    position: relative;
}

.edit__business-container {
    width: 100%;
    position: relative;
}

.edit__changable-container, .edit__business-container {
    display: none;
}

.edit__changable-container.active, .edit__business-container.active {
    display: block;
    animation: edit__fadeIn 0.4s ease-in-out;
}

@keyframes edit__fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.edit__changable-submit {
    width: fit-content;
    padding: 3px;
    background: var(--gradient-login);
    border-radius: 40px;
    margin: 0 auto;
    margin-top: 70px;
    cursor: pointer;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.edit__changable-submit.disabled {
    cursor: default;
    opacity: 0.3;  
}

.edit__changable-submit-inner {
    width: fit-content;
    background: white;
    border-radius: 40px;
    padding: 5px;
    padding-right: 10px;
    padding-left: 10px;
    transition: background 200ms;
}

.changable-submit__text {
    font-family: 'Montserrat', sans-serif;
    font-size: 22px;
    font-weight: 500;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.edit__changable-submit:hover .edit__changable-submit-inner {
    background: transparent;
}

.edit__changable-submit.disabled:hover .edit__changable-submit-inner {
    background: white;
}

.edit__changable-submit-inner.color {
    background: transparent;
}

.edit__back-to-changable {
    color: black;
    position: absolute;
    top: 30px;
    left: 40px;
    font-size: 30px;
    cursor: pointer;
    padding: 20px;
}

.edit__loading {
    width: 100%;
    min-height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
}

.changable-submit__text .spinner-border {
    margin-right: 10px;
    width: 1.5rem;
    height: 1.5rem;
}


@media (max-width: 786px) {
    .edit__box-info {
        margin-top: 41px;
        width: 82%;
    }
}

@media (max-width: 600px) {
    .edit__box-info {
        width: 89%;
    }
}

@media (max-width: 477px) {
    .edit__box-info {
        width: 100%;
        box-shadow: none;
        margin-top: 0px;
    }
}
.artwork-slider-container {
    width: 100%;
}


// Artwork image slider STYLES

.artwork-slider {
    width: 100%;
}


.artwork-slider__item {
    width: 100%;
    display: flex;
    justify-content: center;
    min-height: 300px;
}

.artwork-slider__item-image {
    max-width: 100%;
    max-height: 75vh;
    padding: 0 10px;
    object-fit: scale-down;
    cursor: zoom-in;
}



// Artwork image slider controls STYLES

.artwork-slider__controls {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.artwork-slider__controls-item {
    width: 7%;
    min-width: 46px;
    aspect-ratio: 1/1;
    margin-top: 10px;
    margin-right: 10px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    border: 1px solid #eaebf3;
    opacity: 0.4;
    transition: opacity 200ms;
    cursor: pointer;
}

.artwork-slider__controls-item.active {
    opacity: 1;
}


@media (max-width: 868px) {
    .artwork-slider__item {
        min-height: 90px;
    }
}

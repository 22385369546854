[data-theme="light"] {
    --painting-view-artist: var(--gallery-view-printing-details);
    --painting-view-neumorphism: inset 5px 5px 3px #adaeb4, inset -3px -13px 2px #fff;
    --painting-view-inset: inset 7px 7px 8px #5e5e61,
    inset -7px -7px 8px #ffffff;
}

[data-theme="dark"] {
    --painting-view-artist: #BEBBBB;
    --painting-view-neumorphism: inset 5px 5px 10px #111111, inset -5px -5px 10px #373737;
    --painting-view-inset: inset 7px 7px 8px #0e0e0e,
    inset -7px -7px 8px #3a3a3a;
}

.wishlist-artwork__name {
    font-family: 'Montserrat', sans-serif;
    color: var(--gallery-view-navbar-text);
    transition: var(--change-mode-transition);
    font-weight: 100;
    text-align: center;
    width: 50%;
    margin: 0 auto;
}

.wishlist-artwork__author {
    color: var(--painting-view-artist);
    transition: var(--change-mode-transition);
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    width: 100%;
    font-size: 25px;
    margin-bottom: 30px;
    font-weight: 500;
}


.wishlist-info {
    width: 65%;
    margin: 0 auto;
    padding-bottom: 50px;
}

.wishlist-content {
    width: 100%;
    display: flex;
    position: relative;
}

.wishlist-content__left-panel {
    padding-top: 9px;
    width: 60%;
    position: relative;
}

.wishlist__remove-button {
    color: white;
    position: absolute;
    right: 20px;
    top: 0%;
    transform: translateY(100%);
    width: 31px;
    margin: 0;
}

.wishlist__remove-button svg {
    width: 100%;
    height: 100%;
    stroke: var(--gallery-view-navbar-text);
    overflow: initial;
    cursor: pointer;
}


.wishlist__remove-button svg.active {
    transition: all 200ms;
    animation:like 0.2s 1;
}

@keyframes like {
    0%   { transform: scale(1); }
    60%   { transform: scale(1.2); }
    100% { transform: scale(1.1); }
}

.wishlist-content__right-panel {
    padding-top: 15px;
    width: 40%;
    border-left: var(--border-wishlist);
    padding-bottom: 80px;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.wishlist-content-right-panel__remove-button {
    width: 100%;
    display: flex;
    justify-content: end;
}

.wishlist__remove-button:hover {
    text-decoration: underline;
}

.wishlist__technique {
    width: 100%;
    border-bottom: var(--border-wishlist);
    padding-bottom: 10px;
    transition: var(--change-mode-transition);
}

.wishlist__technique-item {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 17px;
    transition: var(--change-mode-transition);
    margin-bottom: -3px;
    color: var(--cart-product-details-color);
}

.wishlist__description {
    width: 100%;
    transition: var(--change-mode-transition);
    color: var(--gallery-view-navbar-text);
    font-family: 'Montserrat', sans-serif;
    font-size: 17px;
    margin-bottom: 6px;
    text-align: justify;
    margin-top: 25px;
    padding-right: 15px;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.wishlist__audio {
    width: 90%;
    margin-top: 50px;
}

@media (max-width: 1412px) {
    .wishlist-info {
        width: 80%;
    }

    .wishlist-content__right-panel {
        padding-top: 28px;
    }

    .wishlist__remove-button {
        width: 28px;
    }
}

@media (max-width: 1299px) {
    .wishlist-artwork__name {
        font-size: 29px;
    }

    .wishlist-artwork__author {
        font-size: 24px;
    }

    .wishlist__gallery-logo {
        width: 90px;
        height: 90px;
    }
}

@media (max-width: 1000px) {

    .wishlist-info {
        width: 89%;
    }

    .wishlist__description {
        font-size: 16px;
    }

    .wishlist__technique-item {
        font-size: 16px;
    }

    .wishlist-content__right-panel {
        padding-top: 14px;
    }
}

@media (max-width: 883px) {
    .wishlist-artwork__name {
        font-size: 27px;
        width: 80%;
    }

    .wishlist-artwork__author {
        font-size: 22px;
        margin-top: 9px;
        margin-bottom: 33px;
    }
}

@media (max-width: 868px) {
    .wishlist-content {
        border-left: var(--border-wishlist);
        transition: var(--change-mode-transition);
    }

    .wishlist__technique, .wishlist__description, .wishlist__audio {
        padding-left: 20px;
    }

    .wishlist-content {
        flex-wrap: wrap;
    }

    .wishlist__technique {
        width: 100%;
    }

    .wishlist-content__left-panel, .wishlist-content__right-panel {
        width: 100%;
    }

    .wishlist-content__right-panel {
        border-left: none;
    }

    .wishlist-content-right-panel__remove-button {
        display: none;
    }

    .wishlist__gallery-logo {
        width: 80px;
        height: 80px;
        bottom: -30%;
    }

    .wishlist__remove-button {
        font-size: 15px;
    }
}

@media (max-width: 700px) {
    .wishlist-artwork__name {
        font-size: 24px;
        margin-top: -13px;
    }

    .wishlist-artwork__author {
        font-size: 22px;
    }

    .wishlist__banner {
        height: 94px;
    }
}

@media (max-width: 600px) {
    .wishlist-artwork__name {
        font-size: 21px;
    }

    .wishlist-artwork__author {
        font-size: 19px;
    }

    .wishlist__gallery-logo {
        width: 70px;
        height: 70px;
    }

    .wishlist__banner {
        height: 79px;
    }

    .wishlist__technique-item {
        font-size: 15px;
    }
}


@media (max-width: 500px) {

    .wishlist__gallery-logo {
        width: 55px;
        height: 55px;
    }

    .wishlist__banner {
        height: 60px;
    }

    .wishlist__remove-button {
        font-size: 13px;
    }
}

@media (max-width: 455px) {
    .wishlist-artwork__name {
        font-size: 18px;

    }

    .wishlist-artwork__author {
        font-size: 17px;
    }
}


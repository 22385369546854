.new-input, .confirm-input {
    width: 80%;
    height: 30px;
    margin: 0 auto;
    position: relative;
}

.new-input__new, .confirm-input__confirm {
    width: 100%;
    height: 100%;
    padding-left: 10px;
    position: relative;
    border: none;
    z-index: 2;
    transition: all 300ms;
    font-size: 19px;
    background: transparent;
}

.new-input {
    margin-top: 55px;
}

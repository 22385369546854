.cart-details {
    min-height: 200px;
    width: 62%;
    margin: 0 auto;
    padding-bottom: 100px;
    margin-top: 30px;
}

.cart-ticket {
    width: 100%;
}

.cart-ticket__shipping, .cart-ticket__total {
    color: var(--cart-product-details-color);
    font-family: 'Montserrat', sans-serif;
    font-size: 22px;
    margin-bottom: -5px;
    font-weight: 500;
    width: 100%;
    text-align: end;
}

.center-cart-button {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 32px;
}

.cart-button-background {
    background-image: var(--gradient-login);
    width: 200px;
    height: 50px;
    border-radius: 25px;
    padding: 4px;
    cursor: pointer;
}

.cart-button {
    background: var(--background);
    width: 100%;
    height: 100%;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-weight: 500;
    color: var(--gallery-view-navbar-text);
    transition: all 300ms;
}

.cart-button:hover {
    background: none;
}


.cart__clear-button {
    width: fit-content;
    padding: 6px;
    border-radius: 5px;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 16px;
    font-weight: 600;
    color: #333;
    margin-top: 20px;
    cursor: pointer;
    margin-bottom: 40px;
}

@media (max-width: 1083px) {
    .cart-ticket__shipping, .cart-ticket__total {
        font-size: 19px;
    }
}

@media (max-width: 830px) {

    .cart__clear-button {
        font-size: 15px;
        padding: 4px;
    }

    .cart-ticket__shipping, .cart-ticket__total {
        font-size: 17px;
    }
}
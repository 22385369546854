.footer {
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    position: absolute;
    bottom: 0;
}

.footer__copyright-part {
    color: #fff;
    font-family: 'montserrat', sans-serif;
    font-size: 10px;
}
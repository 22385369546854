.select-accordeon {
    width: 100%;
    position: relative;
}

.select-accordeon__selected-option {
    width: 100%;
    display: flex;
    cursor: pointer;
    align-items: center;
    padding-top: 4px;
    padding-bottom: 2px;
}

.selected-option__icon {
    font-size: 27px;
    background: var(--gradient-login);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-right: 10px;
    transition: transform 200ms;
}

.selected-option__option {
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-weight: 500;
    color: grey;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.select-accordeon__selected-option-border {
    width: 100%;
    height: 3px;
    background: var(--gradient-login);
}

.select-accordeon__list {
    width: 100%;
    position: absolute;
    max-height: 0;
    transition: max-height 100ms;
    overflow: hidden;
    background: white;
    border: 1px solid transparent;
    margin-top: 5px;
    pointer-events: none;
    z-index: 2;
}

.select-accordeon.active .select-accordeon__list {
    pointer-events: auto;
    max-height: 200px;
    border: 1px solid #ccc;
    border-bottom: none;
    overflow-y: auto;
    animation: delay-overflow 350ms;
    scrollbar-width: thin;
    scrollbar-color: #353535 #ccc;
    z-index: 3;
}

//WEBKIT SCROLLBAR STYLES
.select-accordeon.active .select-accordeon__list::-webkit-scrollbar {
    width: 0.5em;
}

.select-accordeon.active .select-accordeon__list::-webkit-scrollbar-track {
    background: #ccc;
}

.select-accordeon.active .select-accordeon__list::-webkit-scrollbar-thumb {
    background: #353535;
    border-radius: 20px;
}

@keyframes delay-overflow {
    from { overflow: hidden; }
}

.select-accordeon.active .selected-option__icon {
    transform: rotate(180deg);
}

.select-accordeon__list-item {
    font-family: "Montserrat", sans-serif;
    font-size: 17px;
    font-weight: 600;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
    border-bottom: 1px solid #ccc;
    transition: background 200ms;
}

.select-accordeon__list-item:hover {
    background: #f5f5f5;
}

.select-accordeon__list-item.prefix {
    display: flex;
    align-items: center;
}

.select__item-country-flag {
    width: 25px;
    margin-right: 15px;
}



//REGISTER STYLES

.register__input-group-accordeon .select-accordeon__selected-option {
    padding-left: 10px;
}



.register__input-group-accordeon .select-accordeon__selected-option-border {
    display: none;
}




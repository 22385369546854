.new-artwork__center-button {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 110px;
}

.new-artwork__add-artwork, .new-artwork__loading-button {
    margin-top: 50px;
    padding: 6px;
    padding-left: 40px;
    padding-right: 40px;
    background: rgb(255, 133, 28);
    color: white;
    border-radius: 20px;
    border: none;
    font-size: 20px;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
}

.new-artwork__loading-button {
    opacity: 0.6;
    display: flex;
    align-items: center;
    justify-content: center;
}

.new-artwork__loading-button span {
    margin-left: 13px;
}

.new-artwork__loading-button .spinner-border {
    width: 1.6rem;
    height: 1.6rem;
}

@media (max-width: 786px) {
    .new-artwork__add-artwork, .new-artwork__loading-button {
        padding: 4px;
        padding-left: 40px;
        padding-right: 40px;
        font-size: 18px;
    }
}

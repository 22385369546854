.my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
}

.my-masonry-grid_column {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
}

.masonry {
    width: 100%;
    padding: 20px;
    padding-top:30px;
}

@media (max-width: 900px) {
    .my-masonry-grid_column:nth-child(2), .my-masonry-grid_column:nth-child(3)  {
        padding-left: 19px;
    }  
}

@media (max-width: 720px) {
    .my-masonry-grid_column:nth-child(2) {
        padding-left: 19px;

    }  
}

@media (max-width: 500px) {
    .my-masonry-grid_column:nth-child(2) {
        padding-left: 16px;

    }  
}


.new-artwork__add-audio {
    width: 100%;
    box-shadow: 0 0 6px #ccc;
    border-radius: 5px;
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
}

.new-artwork__add-audio-upload-icon {
    font-size: 34px;
    color: gray;
    margin: 0 auto;
    width: fit-content;
}

.new-artwork__add-audio-text {
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    color: gray;
    text-align: center;
    font-weight: 600;
    margin-bottom: 0px;
}

.new-artwork__add-audio-text.or {
    color: black;
    font-weight: 600;
}

.new-artwork__add-audio-browse {
    border: none;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    color: black;
    text-align: center;
    font-weight: 600;
    background: #ccc;
    padding: 4px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 20px;
    cursor: pointer;
    display: block;
    margin: 0 auto;
    width: fit-content;
    margin-top: 10px;
}

.new-artwork__add-audio.dragging {
    border: 2px dashed red;
}

.new-artwork__add-audio-loaded {
    width: 100%;
    max-width: 500px;
}

.new-artwork__add-audio-loaded .audio-container .rhap_container {
    box-shadow: none;
}

.add-audio__buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 5px;
}

.add-audio__button {
    width: 40px;
    aspect-ratio: 1/1;
    background: rgb(255,133,28);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 50%;
    margin: 0px 10px;
    cursor: pointer;
}

.add-audio__button svg {
    width: 50%;
    fill: white;
}

.add-audio__button i {
    color: white;
    font-size: 26px;
}

.new-artwork__add-audio .new-artwork__image-fail {
    aspect-ratio: auto;
    border: none;
}


@media (max-width: 786px) {
    .add-audio__button {
        width: 35px;
    }
}

@media (max-width: 650px) {
    .new-artwork__add-audio-upload-icon {
        font-size: 30px;
    }

    .new-artwork__add-audio-text {
        font-size: 15px;
    }

    .new-artwork__add-audio-browse {
        font-size: 15px;
    }

    .new-artwork__add-audio {
        height: 150px;
    }


    
}




.artwork-wrap {
    width: 95%;
    margin: 0 auto;
    margin-top: 30px;
    display: flex;
}

.artwork-left {
    width: 65%;
    padding-bottom: 100px;
}

.artwork-right {
    width: 35%;
    border-left: var(--border-wishlist);
    padding-bottom: 100px;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.artwork-info {
    width: 100%;
    padding-left: 20px;
}

.artwork__details {
    width: 100%;
    position: relative;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 17px;
    color: var(--cart-product-details-color);
    font-weight: bold;
    padding-bottom: 12px;
    border-bottom: 2px solid gray;
}

.artwork__details p {
    margin-bottom: -3px;
}

.artwork__like-button {
    color: white;
    position: absolute;
    right: 20px;
    top: 0%;
    transform: translateY(100%);
    width: 31px;
    margin: 0;
}

.artwork__like-button svg {
    width: 100%;
    height: 100%;
    stroke: var(--gallery-view-navbar-text);
    overflow: initial;
    cursor: pointer;
}

.artwork__like-button svg.active {
    transition: all 200ms;
    animation:like 0.2s 1;
}

.artwork__description {
    padding-top: 10px;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 17px;
    color: #FFF;
    margin-bottom: 30px;
    text-align:justify;
}

@keyframes like {
    0%   { transform: scale(1); }
    60%   { transform: scale(1.2); }
    100% { transform: scale(1.1); }
}

.artwork__like-button:hover {
    text-decoration: underline;
}

.artwork__audio {
    width: 90%;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 40px;
}

.artwork__view-in-room {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat', 'sans-serif';
    color: white;
    margin-top: 20px;
    margin-bottom: 35px;
}

.artwork__view-in-room svg {
    width: 65px;
    fill: white;
}

.artwork__view-in-room-text {
    position: relative;
    left: -5px;
    top: 3px;
    font-weight: bold;

}

.artwork__view-in-room-button {
    cursor: pointer;
    border: 1px solid white;
    border-radius: 20px;
    transition: all 300ms;
    height: 48px;
    display: flex;
    align-items: center;
    padding-right: 10px;
}


.artwork__view-in-room-button:hover {
    background: white;
}

.artwork__view-in-room-button:hover svg {
    fill: black;
}

.artwork__view-in-room-button:hover .artwork__view-in-room-text {
    color: black;
}

.artwork__suggestions {
    width: 100%;
    margin-top: 40px;
    padding-left: 20px;
}

.artwork__suggestions-text {
    font-family: 'Montserrat', 'sans-serif';
    font-size: 17px;
    color: white;
    font-weight: 500;
}

.artwork__suggestions-slider {
    width: 100%;
    height: 250px;
}

@media (max-width: 1412px) {
    .artwork__details, .artwork__description {
        font-size: 16px;
    }
}


@media (max-width: 1412px) {
    .artwork__details, .artwork__description {
        font-size: 15px;
    }

    .artwork__like-button {
        width: 28px;
    }
}

@media (max-width: 868px) {
    .artwork-wrap {
        flex-wrap: wrap;
        width: 89%;
    }

    .artwork-left {
        width: 100%;
        padding-bottom: 0;
    }

    .artwork-right {
        width: 100%;
    }

    .artwork__like-button {
        font-size: 15px;
    }

    .artwork__audio {
        margin-top: 40px;
    }
    
}

@media (max-width: 500px) {

    .artwork__like-button {
        font-size: 13px;
    }
}
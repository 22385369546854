.my-artworks__offcanvas {
    border: none !important;
}

.my-artworks__offcanvas .offcanvas-header .btn-close {
    margin-left: auto;
}

.my-artworks__mobile-menu-item {
    display: flex;
    justify-content: end;
}

.my-artworks__offcanvas .artworks-header__search-container.active .artworks-header__search-input {
    width: 250px;
}

.my-artworks__offcanvas .admin-header-accordeon {
    width: 83%;
    margin-top: 20px;
}

.my-artworks__offcanvas .admin-accordeon__selected-option {
    font-size: 15px;
    padding: 3px;
}

.my-artworks__offcanvas .admin-accordeon-options__option {
    font-size: 15px;
}

.my-artworks__offcanvas .admin-header-accordeon.active .admin-accordeon__options {
    padding-top: 40px;
}


.search__form {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.search__input-container {
    width: 50%;
    margin-right: 12px;
    position: relative;
}

.search__input {
    width: 100%;
    padding: 15px;
    background: #242424;
    font-size: 17px;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    outline: none;
    border: none;
    font-weight: 500;
    box-shadow: inset 4px 4px 7px #141414, inset -4px -4px 7px #343434;
}

.search__input::placeholder {
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    color: grey;
    font-size: 17px;
    transition: all 200ms;
}

.search__input:focus::placeholder {
    color: #a1a1a1;
}

.search__button {
    width: 52px;
    aspect-ratio: 1/1;
    border: none;
    background: #242424;
    box-shadow: 4px 4px 5px #141414, -2px -2px 5px #343434;
    display: flex;
    justify-content: center;
    align-items: center;
}

.search__button svg {
    width: 50%;
    fill: white;
    stroke: white;
    stroke-width: 39px;
    overflow: initial;
}

@media (max-width: 916px) {
    .search__input::placeholder {
        font-size: 16px;
    }

    .search__input {
        font-size: 16px;
        padding: 10px;
        padding-left: 12px;
    }

    .search__input-container {
        margin-right: 8px;
        width: 60%;
    }

    .search__button {
        width: 43px;
    }
    
}

@media (max-width: 712px) {
    .search__input::placeholder {
        font-size: 15px;
    }

    .search__input {
        font-size: 15px;
        padding: 8px;
        padding-left: 12px;
    }

    .search__input-container {
        width: 80%;
    }

    .search__button {
        width: 37px;
    }
    
}

@media (max-width: 514px) {

    .search__input-container {
        width: 85%;
    }

    .search__form {
        margin-top:20px;
    }
    
}


.slider-wishlist.swiper {
    width: 100%;
    padding-top: 60px;
    padding-bottom: 50px;
  }
  
.slider-wishlist .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px;
    height: 200px;
}
  
.slider-wishlist .swiper-bg {
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.slider-wishlist .swiper-button-next, .slider-wishlist div.swiper-button-prev {
    top: 46%;
}

.slider-wishlist .swiper-button-next:after, .slider-wishlist .swiper-button-prev:after {
    font-size: 14px !important;
    color: var(--gallery-view-navbar-text);
    transition: var(--change-mode-transition);
}

.slider-wishlist div.swiper-button-prev, .slider-wishlist div.swiper-button-next {
    display: none;
}



@media (min-width: 700px) {
    .slider-wishlist .swiper-slide {
        width: 300px;
        height: 300px;
    }
}

@media (min-width: 1300px) {
    .slider-wishlist .swiper-slide {
        width: 300px;
        height: 400px;
    }
}

@media (min-width: 1652px) {
    .slider-wishlist .swiper-slide {
        width: 300px;
        height: 500px;
    }
}


@media (min-width: 2234px) {
    .slider-wishlist .swiper-slide {
        width: 300px;
        height: 550px;
    }
}

@media (max-width: 1129px){
    .slider-wishlist.swiper {
        padding-top: 40px;
    }
}


@media (max-width: 540px) {
    .slider-wishlist div.swiper-button-prev, .slider-wishlist div.swiper-button-next {
        display: flex;
        background: #323232;
        border-radius: 50%;
        width: 30px;
        justify-content: center;
        height: 30px;
    }

    .slider-wishlist .swiper-bg {
        width: 70%;
        margin: 0 auto;
    }

    .slider-wishlist.swiper {
        padding-top: 15px;
    }
}


@media (max-width: 450px) {
    .slider-wishlist div.swiper-button-prev, .slider-wishlist div.swiper-button-next {
        width: 30px;
        height: 30px;
    }

    .slider-wishlist .swiper-button-next:after, .slider-wishlist .swiper-button-prev:after {
        font-size: 14px !important;
    }

}

.artwork-list {
    width: 100%;
    padding-bottom: 100px;
}

.artwork-list__item {
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.artwork-list__item.colored {
    background: rgba(255,133,28,0.1);
}

.artwork-list__pic-name {
    max-width: 25%;
    width: inherit;
    display: flex;
    align-items: center;
}

.artwork-list__pic {
    min-width: 40%;
    aspect-ratio: 1/1;
    object-fit: contain;
    max-width: 40%;
}

.artwork-list__pic.template {
    background-color: #ccc;
}

.artwork-list__name {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 600;
    color: black;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin: 0px;
    margin-left: 10px;
    font-style: italic;
}

.artwork-list__separator {
    display: none;
}

.artwork-list__column {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: black;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin: 0px;
}

.artwork-list__group {
    width: fit-content;
    display: flex;
    align-items: center;
}

.artwork-list__buttons {
    margin-left: 70px;
    width: fit-content;
    align-items: center;
    display: flex;
}

.artwork-list__button {
    background: rgb(255 133 28);
    border-radius: 50%;
    width: 40px;
    aspect-ratio: 1/1;
    display: flex; 
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 30px;
    cursor: pointer;
}

.artwork-list__button:nth-child(1) {
    margin-right: 8px;
}

.artwork-list__button svg {
    width: 50%;
    fill: white;
}

.artwork-list__button.margin {
    margin-right: 0px;
}


.artwork-list__pic-alternative {
    width: 100%;
    display: none;
}



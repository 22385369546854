[data-theme="dark"] {
    --gallery-view-navbar-text: #FFFFFF;
    --gallery-view-icon-color: invert(100%) sepia(100%) saturate(0%) hue-rotate(122deg) brightness(103%) contrast(103%);
    --box-shadow-navbar:1px 5px 10px black;   
}

[data-theme="light"] {
    --gallery-view-navbar-text: #242424;
    --gallery-view-icon-color: invert(11%) sepia(0%) saturate(1161%) hue-rotate(149deg) brightness(96%) contrast(92%);
    --box-shadow-navbar: 1px 1px 3px black;
}

.navbar {
    width: 100%;
    height: 90px;
    background: var(--background);
    position: sticky;
    transition: background  600ms;
    z-index:4;
    top: 0;
}

.navbar svg {
    overflow: initial;
}

.navbar-painted {
    box-shadow: var(--box-shadow-navbar);
}

.navbar--wrap {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.navbar__center {
    color: var(--gallery-view-navbar-text);
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 40px;
    width: 60%;
    text-align: center;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.navbar__icon {
    width: 20%;
    position: relative;
}

.navbar__logo {
    width: 50px;
    cursor: pointer;
}

.navbar__options {
    width: 20%;
    display: flex;
    justify-content: flex-end;
}

.navbar__search, .navbar__heart, .navbar__cart, .navbar__user, .navbar__mode {
    margin-left: 17px;
    cursor: pointer;
    width: 32px;
    transition: all 300ms ease;
}

.navbar__user {
    width: 25px;
}

.navbar__heart, .navbar__cart, .navbar__search {
    width: 30px;
    display: block;
    height:100%;
    position: relative;
}

.navbar__search {
    width: 28px;
}

.navbar__notification {
    position: absolute;
    top: 0px;
    right: -4px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ffbe2e;
    opacity: 0;
}


.navbar__center.artwork {
    cursor: pointer;
}

.navbar-artwork__name {
    font-size: 33px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.navbar-artwork__artist {
    font-size: 22px;
    color: var(--cart-product-details-color);
    margin-top: -6px;
}

.notification-active {
    opacity: 1;
}

.navbar__cart {
    width: 32px;
}

a {
    outline: none;
}

.navbar__search svg, .navbar__heart svg, .navbar__cart svg, .navbar__user svg, .navbar__notification{
    transition: all 300ms ease;
    fill: var(--gallery-view-navbar-text);
}

.navbar__search:hover svg, .navbar__heart:hover svg, .navbar__cart:hover svg, .navbar__user:hover svg{
    margin-top: -12px;
}

 .navbar__heart:hover .navbar__notification, .navbar__cart:hover .navbar__notification{
    margin-top: -6px;
}

.navbar__center.--suarte-world {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    letter-spacing: 10px;
    font-size: 38px;
}

.navbar__gallery-logo {
    width: 65px;
    cursor: pointer;
    margin-top: 3px;
    aspect-ratio: 1/1;
    object-fit: cover;
}

.navbar-suarte__logo-name {
    width: 20px;
    width: 39px;
    position: relative;
    top: 15px;
    right: 10px;
    margin-top: -20px;
}

.navbar-mobile-container {
    display: flex;
    justify-content: flex-end;
    display: none;
    width: 24%;
}

.navbar__mobile-button {
    display: none;
    position: fixed;
    top: 24px;
    z-index: 1046;
    right: 8px;
    flex-direction: column;
    cursor: pointer;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
}

.navbar-pic__template {
    width: 24%;
    max-width: 70px;
    min-width: 50px;
    aspect-ratio: 1/1;
    background: #414141;
}

.navbar__mobile-button-bar {
    padding-top: 3px;
    width: 30px;
    background-color: var(--gallery-view-navbar-text);
    margin-bottom: 7px;
    transition: transform 400ms ease-in-out, opacity 400ms ease-in-out, background-color 400ms ease-in-out;
    border-radius: 3px;
}

.active .navbar__mobile-button-bar:nth-child(1){
    transform: translateY(7px) rotate(45deg);
    background-color: var(--gallery-view-navbar-text); 
}

.active .navbar__mobile-button-bar:nth-child(2){
    opacity: 0;
    background-color: var(--gallery-view-navbar-text);
}

.active .navbar__mobile-button-bar:nth-child(3){
    transform: translateY(-13px) rotate(-45deg);
    background-color: var(--gallery-view-navbar-text);
}

.navbar__center.template {
    background: #414141;
    padding: 15px;
    width: 60%;
    border-radius: 5px;
}

@media (max-width: 1523px) {
    .navbar__center {
        width: 52%;
    }

    .navbar__icon {
        width: 24%;
    }

    .navbar__options {
        width: 24%;
    }

    .navbar--wrap {
        width: 93%;
    }

    .navbar__search, .navbar__heart, .navbar__cart, .navbar__user, .navbar__mode {
        margin-left: 18px;
    } 
}

@media (max-width: 1310px) {
    .navbar-artwork__name {
        font-size: 28px;
    }

    .navbar-artwork__artist {
        font-size: 19px;
    }
}

@media (max-width: 1250px) {

    .navbar__center {
        font-size: 40px;
    }

    .navbar__logo {
        width: 44px;
    }

    .navbar__search {
        width: 25px;
    }

    .navbar__heart {
        width: 27px;
    }

    .navbar__cart {
        width: 28px;
    }

    .navbar__user {
        width: 22px;
    }

    .navbar__mode {
        width: 28px;
    }

    .navbar__search, .navbar__heart, .navbar__cart, .navbar__user, .navbar__mode {
        margin-left: 12px;
    } 
}

@media (max-width: 1062px) {
    .navbar__center.--suarte-world {
        font-size: 33px;
    }

    .navbar-suarte__logo-name {
        width: 36px;
    }

    .navbar__logo.--suarte-world {
        width: 69px;
    }

    .navbar-artwork__name {
        font-size: 25px;
    }

    .navbar-artwork__artist {
        font-size: 19px;
    }
    
}

@media (max-width: 1040px) {
    .navbar__center {
        font-size: 35px;
    }

    .navbar__logo {
        width: 41px;
    }
}

@media (max-width: 870px) {

    .navbar__options {
        display: none;
    }

    .navbar-mobile-container {
        display: flex;
        width: 15%;
    }

    .navbar__center {
        width: 68%;
    }

    .navbar__icon {
        width: 15%;
    }  

    .navbar__center {
        font-size: 29px;
    }

    .navbar__center.--suarte-world {
        padding-left: 5%;
    }

    .navbar__mobile-button {
        display: flex;
    }
}

@media (max-width: 756px) {
    .navbar__gallery-logo {
        width: 54px;
    }
}

@media (max-width: 706px) {
    .navbar-artwork__name {
        font-size: 20px;
    }

    .navbar-artwork__artist {
        font-size: 19px;
    }
}

@media (max-width: 700px) {
    .navbar__center.--suarte-world {
        font-size: 23px;
        letter-spacing: 7px;
    }

    .navbar-suarte__logo-name {
        width: 30px;
        right: 7px;
    }

    .navbar__logo.--suarte-world {
        width: 59px;
    }
}

@media (max-width: 620px) {

    .navbar__center {
        font-size: 29px;
    }

    .navbar__logo {
        width: 36px;
    }

    .navbar__mobile-button-bar {
        width: 26px;
    }
}

@media (max-width: 570px) {
    .navbar__center {
        font-size: 26px;
    }
}

@media (max-width: 546px) {
    .navbar-artwork__name {
        font-size: 18px;
    }

    .navbar-artwork__artist {
        font-size: 17px;
    }
}


@media (max-width: 514px) {
    .navbar__center.--suarte-world {
        font-size: 18px;
        letter-spacing: 5px;
    }


    .navbar-suarte__logo-name {
        width: 28px;
        right: 5px;
    }

    .navbar__logo.--suarte-world {
        width: 52px;
    }

    .navbar__gallery-logo {
        width: 52px;
    }
}


@media (max-width: 479px) {

    .navbar__center {
        font-size: 22px;
    }

}

@media (max-width: 419px) {
    .navbar__center.--suarte-world {
        font-size: 17px;
        letter-spacing: 4px;
    }

    .navbar-suarte__logo-name {
        width: 26px;
        right: 4px;
    }

    .navbar__logo.--suarte-world {
        width: 49px;
    }

    .navbar__gallery-logo {
        width: 49px;
    }
}

@media (max-width: 376px) {
    .navbar__logo.--suarte-world {
        width: 46px;
    }
}







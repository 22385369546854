.tag-template__container {
    width: 100%;
    display: flex;
    align-items: center;
}

.tag-template__cuerda {
    width: 110px;
    position: relative;
    z-index: 1;
    fill: white; 
    stroke: #242424; 
    stroke-miterlimit:10; 
    stroke-width:2px;
}

.tag-template__svg {
    width: 200px;
    stroke-width: 8px;
    transition: all 200ms ease-in-out;
}

.tag-template__svg-circle {
    fill: #242424;
}

.tag-template__svg-circle.label-selected {
    stroke: #242424;
}

.tag-template__price {
    width: fit-content;
    position: relative;
    left: -25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    overflow:hidden;
}

.tag-template__price-text {
    position: absolute;
    color: white;
    font-size: 23px;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    margin-left: 24px;
}


@media (max-width: 1142px) {
    .tag-template__svg {
        width: 161px;
    }

    .tag-template__cuerda {
        width: 90px;
    }

    .tag-template__price {
        left: -20px;
    }

    .tag-template__price-text {
        font-size: 20px;
    }
}




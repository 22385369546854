.my-artworks__container {
    width: 100%;
    min-height: 100vh;
    background-color: white;
}

.my-artworks__wrap {
    width: 90%;
    margin: 0 auto;
    padding-top: 30px;
    max-width: 1500px;
}

.my-artworks__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.new-artwork-link__container.active {
    width: 200px;
}

.new-artwork__link {
    text-decoration: none;
    width: 45px;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(255 133 28);
    border: 4px solid rgb(255 133 28);
    font-size: 30px;
    border-radius: 50%;
    font-weight: bold;
    transition: transform 0.3s ease-in-out;
    position: relative;
}

.new-artwork__link i {
    position: absolute;
}

.new-artwork__link:hover {
    color: rgb(255 133 28);
    transform: rotate(180deg);
}

.my-artworks__header-right {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

//SEARCH ANIMATION: THE ONLY HARDCODED VALUES IS SEARCHCONTAINER BORDER-RADIUS AND INITAL WIDTH AND HEIGHT OF SPAN BECAUSE WE CANT USE BORDER-RADIUS: 50% BECAUSE WE WANT TO MANTAIN PROPORTIONS
.artworks-header__search-container {
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid rgb(255,133,28);
    box-sizing: initial;
    border-radius: 43px;
}

.artworks-header__search-input {
    outline: none;
    border: none;
    width: 0px;
    font-size: 17px;
    font-weight: 500;
    color: black;
    transition: all 0.3s ease-in-out;
    font-family: 'Montserrat', sans-serif;
    background: transparent;
}

.artworks-header__search-icon {
    width: 43px;
    height: 43px;
    color:rgb(255,133,28);
    font-size: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.artworks-header__search-container.active .artworks-header__search-input{
    width: 500px;
    padding: 8px;
    padding-left:15px;
}

.profile-mobilemenu__width {
    width: 320px;
    border-left: none;
}


//FILTER STYLES (THIS WILL BE IN ANOTHER COMPONENT AND I WILL REDOIT XD)
.artworks-header__filter-container {
    width: 20%;
    margin-left: 10px;
}

.my-artworks {
    width: 100%;
    margin-top: 50px;
}

.my-artworks__menu-mobile-button {
    width: 36px;
    border: 2px solid rgb(255,133,28);
    font-size: 23px;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: rgb(255 133 28);
    font-weight: bold;
    display: none;
    cursor: pointer;
}


@media (max-width: 1629px) {
    
    .artworks-header__filter-container {
        width: 26%;
    }

    .artworks-header__filter-container .admin-accordeon__selected-option, .artworks-header__filter-container .admin-accordeon-options__option {
        font-size: 17px;
    }

    .artworks-header__search-container.active .artworks-header__search-input {
        padding: 0px;
        padding-left: 15px;
    }

    .artworks-header__search-icon {
        width: 36px;
        height: 36px;
    }

    .new-artwork__link {
        width: 39px;
        font-size: 26px;
    }
}

@media (max-width: 1583px) {
    .my-artworks .artwork-list__button {
        width: 38px;
        font-size: 22px;
    }

    .my-artworks .artwork-list__column, .my-artworks .artwork-list__name {
        font-size: 17px;
    }
      
}

@media (max-width: 1401px) {
    .my-artworks .artwork-list__pic {
        min-width: 34%;
    }

    .my-artworks .artwork-list__button {
        width: 36px;
        font-size: 20px;
    }

    .my-artworks .artwork-list__buttons {
        margin-left: 40px;
    }  
}



@media (max-width: 1295px) {
    .artworks-header__search-container.active .artworks-header__search-input {
        width: 350px;
    }
     
}

@media (max-width: 1213px) {
    .artworks-header__filter-container {
        width: 34%;
    }

    .my-artworks .artwork-list__button {
        width: 35px;
        font-size: 17px;
    }

    .my-artworks .artwork-list__buttons {
        margin-left: 30px;
    } 
    
    .my-artworks .artwork-list__pic-name {
        max-width: 34%;
    }
}

@media (max-width: 1035px) {
    
    .artworks-header__filter-container .admin-accordeon__selected-option, .artworks-header__filter-container .admin-accordeon-options__option {
        font-size: 16px;
    }

    .artworks-header__search-input {
        font-size: 16px;
    }

    .artworks-header__search-icon {
        width: 34px;
        height: 34px;
        padding-bottom:2px;
        padding-right: 1px;
    }

    .new-artwork__link {
        width: 39px;
        font-size: 26px;
    }

    .my-artworks__header-right {
        width: 90%;
    }

    .artworks-header__search-container.active .artworks-header__search-input {
        width: 280px;
    }

    .my-artworks .artwork-list__column, .my-artworks .artwork-list__name {
        font-size: 16px;
    }

    .my-artworks .artwork-list__pic {
        min-width: 30%;
    }   
}

@media (max-width: 919px) {
    .my-artworks .artwork-list__column, .my-artworks .artwork-list__name {
        font-size: 15px;
    }

    .my-artworks .artwork-list__button {
        width: 34px;
        font-size: 17px;
    }

    .my-artworks .artwork-list__buttons {
        margin-left: 25px;
    } 
}

@media (max-width: 869px) {
    
    .artworks-header__search-icon {
        width: 30px;
        height: 30px;
        font-size: 20px;
        padding-bottom:2px;
        padding-right: 1px;
    }
    .new-artwork__link {
        width: 36px;
        border: 3px solid rgb(255,133,28);
        font-size: 23px;
    }

    .artworks-header__filter-container .admin-accordeon__selected-option {
        padding: 3px;
    }

    .artworks-header__filter-container .admin-header-accordeon.active .admin-accordeon__options {
        padding-top: 40px;
    }

    .artworks-header__filter-container .admin-accordeon__selected-option, .artworks-header__filter-container .admin-accordeon-options__option {
        font-size: 15px;
    }

    .artworks-header__search-input {
        font-size: 15px;
    }
}

@media (max-width: 786px) {
    .my-artworks__wrap {
        width: 94%;
        padding-top: 80px;
    }

    .my-artworks {
        margin-top: 25px;
    }

    .artworks-header__search-container.active .artworks-header__search-input {
        width: 220px;
    }

    .artworks-header__filter-container .admin-accordeon-options__option {
        padding-left: 10px;
        padding-right: 10px;
    }
}


@media (max-width: 600px) {
    .my-artworks .artwork-list__item {
        display: block;
        width: 80%;
        margin: 0 auto;
        box-shadow: 1px 1px 6px #b5b5b5, -1px -1px 6px #ccc;
        // border: 2px solid #ccc;
        padding: 17px;
        margin-bottom: 20px; 
        background: white !important;
    }

    .my-artworks .artwork-list__pic-name {
        display: block;
        max-width: 100%;
        width: 100%;
    }

    .my-artworks .artwork-list__group {
        display: block;
        width: 100%;
    }

    .my-artworks .artwork-list__buttons {
        margin: 0 auto;
    }

    .my-artworks .artwork-list__pic {
        display: none;
    }

    .my-artworks .artwork-list__pic-alternative {
        display: block;
        padding: 15px;
        background: #eaebf3;
        box-shadow: inset 3px 3px 4px #a6a7ad, inset -3px -3px 4px #a6a7ad;
        margin-bottom: 15px;
    }

    .my-artworks .artwork-list__name {
        margin-left: 0px;
        font-size: 16px;
    }

    .my-artworks {
        margin-top: 48px;
    }

    .my-artworks .artwork-list__separator {
        display: block;
        width: 57%;
        height: 2px;
        background: #ccc;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .my-artworks .artwork-list__button {
        width: 36px;
        font-size: 20px;
    }
}

@media (max-width: 566px) {
    
    .my-artworks__header-right {
        display: none;
    }

    .my-artworks__menu-mobile-button {
        display: flex;
    }

    .my-artworks .artwork-list__name {
        margin-left: 0px;
        font-size: 15px;
    }
    .my-artworks .artwork-list__column {
        font-size: 14px;
    }

    .my-artworks__header {
        width: 90%;
        margin: 0 auto;
    }
}

@media (max-width: 432px) {
    .my-artworks .artwork-list__item {
        width: 90%;
        padding: 10px;
    }

    .my-artworks .artwork-list__button {
        width: 34px;
        font-size: 17px;
    }
}















